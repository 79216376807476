import produce from 'immer';
import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_PUBLISH_DATE, UPDATE_PUBLISH_IMMEDIATELY } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  publishDate: 0,
  publishImmediately: null
};

// Note, our API response has `publishDateLocalTime` too, but I'm choosing to ignore it for now. It
// seems un-to-rarely-used

export const basePublishReducerAsJSObject = (state = initialState, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        const {
          publishImmediately,
          publishDate
        } = content;
        return Object.assign(draft, {
          publishDate,
          publishImmediately
        });
      }
    case UPDATE_PUBLISH_DATE:
      draft.publishDate = payload;
      return draft;
    case UPDATE_PUBLISH_IMMEDIATELY:
      draft.publishImmediately = payload.publishImmediately;
      draft.publishDate = payload.publishDate;
      return draft;
    default:
      {
        return draft;
      }
  }
});

// Note, our API response has `publishDateLocalTime` too, but I'm choosing to ignore it for now. It
// seems un-to-rarely-used

const basePublishReducer = (state = ImmutableMap(initialState), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        const {
          publishImmediately,
          publishDate
        } = content;
        return state.merge({
          publishDate,
          publishImmediately
        });
      }
    case UPDATE_PUBLISH_DATE:
      return state.set('publishDate', payload);
    case UPDATE_PUBLISH_IMMEDIATELY:
      return state.set('publishImmediately', payload.publishImmediately).set('publishDate', payload.publishDate);
    default:
      {
        return state;
      }
  }
};
export default basePublishReducer;