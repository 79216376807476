import { useEffect, useState } from 'react';
import { FTS0002_GROUP_PARAMETER_KEY, FTS0002_GROUP_PARAMETER_OPTIONS, FTS0002_TREATMENT_KEY, PARAMETERS } from '../constants/experimentKeys';
import { laboratoryClient } from '../utils/laboratoryUtil';
export const logFTS0002Exposure = () => {
  laboratoryClient.logExposure(FTS0002_TREATMENT_KEY);
};

// https://tools.hubteam.com/laboratory/experiments/edit/fts-0002?tab=workflow&step=0
export const useIsFTS0002Variant = shouldShowRemoveBrandingMessage => {
  const [isFTS0002Variant, setIsFTS0002Variant] = useState(false);
  const [isFTS0002Fetched, setIsFTS0002Fetched] = useState(false);
  useEffect(() => {
    if (!isFTS0002Fetched) {
      if (shouldShowRemoveBrandingMessage) {
        laboratoryClient.resolve(treatments => {
          if (Object.keys(treatments).length) {
            const treatment = treatments[FTS0002_TREATMENT_KEY];
            const cohort = treatment[PARAMETERS][FTS0002_GROUP_PARAMETER_KEY];
            const isVariant = cohort === FTS0002_GROUP_PARAMETER_OPTIONS.variant;
            setIsFTS0002Variant(isVariant);
          }
          setIsFTS0002Fetched(true);
          logFTS0002Exposure();
        });
      } else {
        setIsFTS0002Variant(false);
        setIsFTS0002Fetched(true);
      }
    }
  }, [isFTS0002Fetched, shouldShowRemoveBrandingMessage]);
  return {
    isFTS0002Variant,
    isFTS0002Fetched
  };
};