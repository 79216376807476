import keyMirror from 'react-utils/keyMirror';
export const ModalTypes = keyMirror({
  EDIT_VIDEO_MODAL: null,
  FILE_MANAGER: null,
  VIDEO_PICKER: null,
  VIDEO_CONVERSION_ASSET_OPTIONS: null,
  HTML_EDITOR: null,
  LINK: null,
  CTA: null,
  FORM: null,
  PERSONALIZATION: null,
  MEDIA_MODAL: null,
  WARNING: null,
  ALERT: null
});