export const TOPIC_NAMES = {
  CLOSE_COPILOT: 'CLOSE_COPILOT',
  COLLABORATE_COPILOT_ACTON: 'COLLABORATE_COPILOT_ACTON',
  COLLABORATE_COPILOT_GET_CONTEXT: 'COLLABORATE_COPILOT_GET_CONTEXT',
  COLLABORATE_PARENT_APP_MESSAGE: 'COLLABORATE_PARENT_APP_MESSAGE',
  LAUNCH_FROM_COPILOT_PREVIEW: 'LAUNCH_FROM_COPILOT_PREVIEW',
  OPEN_PROMPT_TEMPLATE: 'OPEN_PROMPT_TEMPLATE',
  OPEN_ACTION_FORM: 'OPEN_ACTION_FORM',
  OPEN_COPILOT: 'OPEN_COPILOT',
  OPEN_FULLSCREEN_MESSAGE_VIEWER: 'OPEN_FULLSCREEN_MESSAGE_VIEWER',
  PROMPT_USER_FOR_MORE_INFO: 'PROMPT_USER_FOR_MORE_INFO',
  SEND_ACTION: 'SEND_ACTION',
  SEND_MESSAGE: 'SEND_MESSAGE'
};