'use es6';

import { FETCH_TEMPLATE_INFO_FAIL, FETCH_TEMPLATE_PREVIEW_URL_FAIL, FETCH_TEMPLATE_INFO_SUCCESS, FETCH_TEMPLATE_PREVIEW_URL_SUCCESS, FETCH_TEMPLATE_PREVIEW_URL_REQUEST, FETCH_TEMPLATE_INFO_REQUEST, FETCH_ADDABLE_SECTIONS_SUCCESS, FETCH_ADDABLE_SECTIONS_FAILURE, FETCH_ADDABLE_SECTIONS_REQUEST, DELETE_SECTION_SUCCESS, DELETE_SECTION_FAILURE, DELETE_SECTION_REQUEST, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAILURE, SAVE_SECTION_REQUEST, FETCH_MARKETER_SECTIONS_REQUEST, FETCH_MARKETER_SECTIONS_SUCCESS, FETCH_MARKETER_SECTIONS_FAILURE, MARK_ADDABLE_SECTIONS_AS_STATE } from 'ContentEditorUI/redux/actions/actionTypes';
import { OPEN_EDIT_TEMPLATE_MODAL, CLOSE_EDIT_TEMPLATE_MODAL } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import http from 'hub-http/clients/apiClient';
import { TEMPLATE_INFO_URL, TEMPLATE_URL } from 'ContentEditorUI/lib/templateInfo/constants';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { getAsyncScreenshot } from 'ContentEditorUI/api/HubShotApi';
import { getTemplateIsFromLayout, getTemplateGeneratedFromLayoutId, getEditTemplateUrl, getTemplateId, getIsLayoutRequestSucceededIfNeeded } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { showErrorWithHTML } from 'ContentEditorUI/utils/errorUtils';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { fetchLayout } from 'ContentEditorUI/redux/actions/layoutActions';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
import { fetchAddableSections as fetchAddableSectionsRequest, deleteSection as deleteSectionRequest, saveNewSection, fetchMarketerSections as fetchMarketerSectionsRequest } from 'ContentEditorUI/api/SectionsApi';
import { exportTreeFragmentAsLayoutDataJson } from '../../utils/layoutSectionTreeUtils';
import { getThemePath } from '../selectors/themeSelectors';
import { getLayoutSectionTreeById, getModuleById } from '../selectors/moduleSelectors';
import { getSchemaForModule } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getCurrentCreateNewSectionInfo } from '../selectors/sectionsSelectors';
import { batchTreeMutations } from 'layout-data-lib/CellAndRowsTree/insertHelpers';
import { getTemplatePath, getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { RESTRICTED_MODULE } from 'ContentEditorUI/containers/sections/utils';
import { getFullUrl } from 'hubspot-url-utils';
export const getTemplatePreviewUrl = info => {
  const env = enviro.getShort() === 'qa' ? 'qa' : '';
  const portalId = PortalIdParser.get();
  return `https://preview${env}.hs-sites.com/_hcms/preview/template/multi?template_file_path=${encodeURIComponent(info.path)}&portalId=${portalId}&hs_preview_key=${info.previewKey}`;
};
export const closeEditTemplateModal = () => {
  return {
    type: CLOSE_EDIT_TEMPLATE_MODAL
  };
};
export const openEditTemplateModal = () => {
  return {
    type: OPEN_EDIT_TEMPLATE_MODAL,
    iframeOnly: false
  };
};

// REQUEST FAILURES ---------------------->

const fetchTemplateInfoFailure = error => {
  return {
    type: FETCH_TEMPLATE_INFO_FAIL,
    payload: error
  };
};
const fetchTemplatePreviewUrlFailure = previewImageUrl => {
  return {
    type: FETCH_TEMPLATE_PREVIEW_URL_FAIL,
    payload: previewImageUrl
  };
};
const fetchAddableSectionsFailure = () => {
  return {
    type: FETCH_ADDABLE_SECTIONS_FAILURE
  };
};
const deleteSectionFailure = () => {
  return {
    type: DELETE_SECTION_FAILURE
  };
};
const saveSectionFailure = resp => {
  const errorType = resp.status === 500 ? 'UNKNOWN' : resp.responseJSON.subCategory;
  return {
    type: SAVE_SECTION_FAILURE,
    payload: {
      errorType
    }
  };
};
const saveSectionFailureFromRestrictedModule = (errorType, count) => {
  return {
    type: SAVE_SECTION_FAILURE,
    payload: {
      errorType,
      count
    }
  };
};
const fetchMarketerSectionsFailure = () => {
  return {
    type: FETCH_MARKETER_SECTIONS_FAILURE
  };
};

// REQUEST SUCCESSES --------------------->

const fetchTemplateInfoSuccess = templateInfo => {
  return {
    type: FETCH_TEMPLATE_INFO_SUCCESS,
    payload: templateInfo
  };
};
const fetchTemplatePreviewUrlSuccess = previewImageUrl => {
  return {
    type: FETCH_TEMPLATE_PREVIEW_URL_SUCCESS,
    payload: previewImageUrl
  };
};
const fetchAddableSectionsSuccess = sections => {
  return {
    type: FETCH_ADDABLE_SECTIONS_SUCCESS,
    payload: sections
  };
};
const deleteSectionSuccess = sectionId => {
  return {
    type: DELETE_SECTION_SUCCESS,
    payload: sectionId
  };
};
const saveSectionSuccess = section => {
  FloatingAlertStore.addAlert({
    type: 'success',
    message: I18n.text('marketerSections.saveSuccess')
  });
  return {
    type: SAVE_SECTION_SUCCESS,
    payload: Object.assign({}, section, {
      marketerSection: true
    })
  };
};
const fetchMarketerSectionsSuccess = marketerSections => {
  return {
    type: FETCH_MARKETER_SECTIONS_SUCCESS,
    payload: marketerSections
  };
};

// REQUESTS ------------------------------>

export const fetchTemplatePreview = url => (dispatch, getState) => {
  const state = getState();
  const contentId = getContentModelId(state);
  dispatch({
    type: FETCH_TEMPLATE_PREVIEW_URL_REQUEST
  });
  const success = resp => dispatch(fetchTemplatePreviewUrlSuccess(resp));
  const error = resp => dispatch(fetchTemplatePreviewUrlFailure(resp));
  getAsyncScreenshot(url, contentId).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchTemplateInfo = (itemTemplatePath, shouldFetchLayoutAfter = false) => dispatch => {
  dispatch({
    type: FETCH_TEMPLATE_INFO_REQUEST
  });
  const success = resp => {
    if (resp) {
      if (!resp.userMeta || !resp.userMeta.thumbnailPath) {
        dispatch(fetchTemplatePreview(getTemplatePreviewUrl(resp)));
      }
      dispatch(fetchTemplateInfoSuccess(resp));
      if (shouldFetchLayoutAfter && resp.generatedFromLayoutId) {
        dispatch(fetchLayout(resp.generatedFromLayoutId));
      }
    } else {
      dispatch(fetchTemplateInfoFailure(resp));
    }
  };
  const error = resp => dispatch(fetchTemplateInfoFailure(resp));
  http.get(`${TEMPLATE_INFO_URL}/${encodeURIComponent(itemTemplatePath)}`, {
    query: {
      includeDeleted: true
    }
  }).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchAddableSections = themePath => (dispatch, getState) => {
  dispatch({
    type: FETCH_ADDABLE_SECTIONS_REQUEST
  });
  const templatePath = getTemplatePath(getState());
  const success = resp => {
    dispatch(fetchAddableSectionsSuccess(resp));
  };
  const error = () => {
    dispatch(fetchAddableSectionsFailure());
  };
  fetchAddableSectionsRequest(themePath, templatePath).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchMarketerSections = themePath => dispatch => {
  dispatch({
    type: FETCH_MARKETER_SECTIONS_REQUEST
  });
  const success = marketerSections => {
    dispatch(fetchMarketerSectionsSuccess(marketerSections));
  };
  const error = () => {
    dispatch(fetchMarketerSectionsFailure());
  };
  fetchMarketerSectionsRequest(themePath).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const deleteSection = sectionId => dispatch => {
  dispatch({
    type: DELETE_SECTION_REQUEST
  });
  const success = () => {
    dispatch(deleteSectionSuccess(sectionId));
  };
  const error = () => {
    dispatch(deleteSectionFailure);
  };
  deleteSectionRequest(sectionId).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const saveNewCustomSection = ({
  name,
  description,
  screenshotUrl
}) => (dispatch, getState) => {
  dispatch({
    type: SAVE_SECTION_REQUEST
  });
  const state = getState();

  // Get the current row id and layout section id we care about
  const currentCreateNewSectionInfo = getCurrentCreateNewSectionInfo(state);
  const {
    sectionName,
    layoutSectionId
  } = currentCreateNewSectionInfo;
  const targetLayoutSectionTree = getLayoutSectionTreeById(state, {
    layoutSectionId
  });
  const themePath = getThemePath(state);
  let isSmartSection = false;

  // Modules in a tree typically don't have the original schema module id
  // associated with it. Because this section will be rehydrated later
  // with new module names, we need to look up schema id now and store it
  // on the tree, so when it is rehydrated later we know what kind of
  // module it is.

  const restrictedModules = [];
  const {
    tree: treeUpdatedWithModuleIds
  } = batchTreeMutations(targetLayoutSectionTree, mutableTree => {
    mutableTree.iterateFrom(sectionName, {
      iterateFn: ({
        cell
      }) => {
        if (cell && cell.isModule()) {
          const moduleInstance = getModuleById(state, cell.getName());
          const moduleSchema = getSchemaForModule(state, moduleInstance);
          if (moduleSchema && !moduleSchema.isAvailableForNewContent) {
            restrictedModules.push({
              id: moduleSchema.moduleId,
              label: moduleSchema.label
            });
          }
          if (moduleInstance.get('definition_id')) {
            isSmartSection = true;
          }
          mutableTree.mergeIntoCellParams(cell.getName(), {
            module_id: moduleSchema.moduleId
          });
        }
      }
    });
  });
  const sectionNode = treeUpdatedWithModuleIds.findRow(sectionName);

  // Get the JSON representation of the layout tree
  const sectionTree = exportTreeFragmentAsLayoutDataJson(sectionNode);
  const savePayload = {
    sectionTree,
    name,
    description,
    screenshotUrl,
    themePath,
    isSmartSection
  };
  const success = resp => {
    dispatch(saveSectionSuccess(resp));
    dispatch({
      type: MARK_ADDABLE_SECTIONS_AS_STATE
    });
  };
  const error = resp => {
    dispatch(saveSectionFailure(resp));
  };

  //do not allow save if one or more modules in the section has
  //isAvailableForNewContent set to false
  if (restrictedModules.length === 0) {
    saveNewSection(savePayload).then(success, error).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  } else {
    dispatch(saveSectionFailureFromRestrictedModule(RESTRICTED_MODULE, restrictedModules.length));
  }
};

// EDIT ACTIONS ------------------------------->

export const handleEditClick = () => (dispatch, getState) => {
  const state = getState();
  const generatedFromLayoutId = getTemplateGeneratedFromLayoutId(state);
  const isFromLayout = getTemplateIsFromLayout(state);
  const editTemplateUrl = getEditTemplateUrl(state);
  const appDomain = getFullUrl('app');
  const isLayoutRequestSucceededIfNeeded = getIsLayoutRequestSucceededIfNeeded(state);
  UsageTracker.trackEditorInteraction({
    action: 'Template Action',
    event: 'edit-template'
  });
  if (generatedFromLayoutId || isFromLayout) {
    if (isLayoutRequestSucceededIfNeeded) {
      dispatch(openEditTemplateModal());
    } else {
      showErrorWithHTML({
        action: 'componentNoLayout'
      });
    }
  } else {
    window.open(`${appDomain}${editTemplateUrl}`);
  }
};

// RESTORE ACTIONS ------------------>

export const handleRestoreMissingTemplate = () => (dispatch, getState) => {
  const state = getState();
  const templateId = getTemplateId(state);
  return http.put(`${TEMPLATE_URL}/${templateId}/restore-deleted`).then(() => window.location.reload(), () => {
    FloatingAlertStore.addAlert({
      type: 'danger',
      titleText: I18n.text('templateMissing.errorRestoring.title'),
      message: I18n.text('templateMissing.errorRestoring.message')
    });
  });
};