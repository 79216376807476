import http from 'hub-http/clients/apiClient';
import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import hubapiStack from 'hub-http/stacks/hubapi';
import { retry } from 'hub-http/middlewares/core';

// Retry fetching CRM object if no results are returned
// due to the CRM Lag of the object creation.
const httpCrmObjectFetchRetry = promiseClient(createStack(retry(response => response.responseJSON.results.length === 0, {
  maxRetries: 10
}), hubapiStack));
function fetchObjectFromCrm({
  objectType,
  crmIdProperty,
  selectedAssetCrmId
}) {
  return httpCrmObjectFetchRetry.post(`/crm-search/search`, {
    data: {
      count: 1,
      offset: 0,
      objectTypeId: objectType,
      requestOptions: {
        allPropertiesFetchMode: 'latest_version',
        includeAllProperties: true
      },
      filterGroups: [{
        filters: [{
          operator: 'EQ',
          property: crmIdProperty,
          value: selectedAssetCrmId
        }]
      }]
    }
  }).then(response => {
    return response.results[0].objectId;
  });
}
export function saveCampaign({
  objectType,
  crmIdProperty,
  selectedAssetCrmId,
  currentCampaignGuid,
  updatedCampaignGuid,
  onSuccess = () => {},
  onFailure = () => {}
}) {
  const searchRequest = crmIdProperty ? fetchObjectFromCrm({
    objectType,
    crmIdProperty,
    selectedAssetCrmId
  }) : Promise.resolve(selectedAssetCrmId);
  return searchRequest.then(assetObjectId => {
    // Remove existing campaign on any change (removal or select new campaign).
    const deletePromise = currentCampaignGuid ? http.delete('/campaigns/associations/assets/v2/delete', {
      data: {
        campaignGuids: [currentCampaignGuid],
        assetObjectType: objectType,
        crmAssetIds: [assetObjectId]
      }
    }) : Promise.resolve();

    // On new campaign selected, associated it.
    if (updatedCampaignGuid !== '') {
      return deletePromise.then(() => http.put('/campaigns/associations/assets/v2/create', {
        data: {
          campaignGuids: [updatedCampaignGuid],
          assetObjectType: objectType,
          crmAssetIds: [assetObjectId]
        }
      })).then(onSuccess).catch(onFailure);
    }
    return deletePromise.then(onSuccess).catch(onFailure);
  });
}
export default saveCampaign;