import BackToCampaignLink from '../components/BackToCampaignLink';
import useGetBackToCampaignParams from './useGetBackToCampaignParams';
export default function useBackToCampaign() {
  const {
    fromCampaignId
  } = useGetBackToCampaignParams();
  return {
    shouldDisplayBackToCampaign: !!fromCampaignId,
    BackToCampaignComponent: BackToCampaignLink
  };
}