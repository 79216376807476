import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import inpageActionTypes from 'ContentEditorUI/redux/actions/inpageActionTypes';
// @ts-expect-error not yet migrated
import getEventBus from 'ContentEditorUI/utils/getEventBus';
import { fetchSectionWithMeta } from 'ContentEditorUI/redux/actions/sectionMarkupRequestActions';
const SectionMarkupRequestContainer = () => {
  const bus = getEventBus();
  const dispatch = useDispatch();
  const handleSectionHtmlRequest = useCallback(payload => {
    const {
      message
    } = payload;
    const {
      sectionName,
      sectionVersion
    } = message;
    dispatch(fetchSectionWithMeta(bus, sectionName, sectionVersion)).catch(err => {
      throw err;
    });
  }, [dispatch, bus]);

  // Sets up callback to run when inpage post messages to fetch a section's html
  useEffect(() => {
    bus.on(`post:${inpageActionTypes.SECTION_HTML_REQUESTED}`, handleSectionHtmlRequest);
    return () => {
      bus.off(`post:${inpageActionTypes.SECTION_HTML_REQUESTED}`, handleSectionHtmlRequest);
    };
  }, [bus, handleSectionHtmlRequest]);
  return null;
};
export default SectionMarkupRequestContainer;