import http from 'hub-http/clients/apiClient';
export const API_PATH = 'hubdb/api/v2/tables';
export const CONTENT_TYPES_SCHEMA_PATH = 'cms/v3/content-types/schemas';
export function updateRow(tableId, rowId, data) {
  return http.put(`${API_PATH}/${tableId}/rows/${rowId}`, {
    data
  });
}
export function updateCell(tableId, rowId, columnId, data) {
  return http.put(`${API_PATH}/${tableId}/rows/${rowId}/cells/${columnId}`, {
    data
  });
}
export function fetchContentTypeTableSchema(contentTypeName, tableName) {
  return http.get(`${CONTENT_TYPES_SCHEMA_PATH}/${contentTypeName}/${tableName}`, {
    query: {
      getLocalizedSchema: true
    }
  });
}
export function createContentTypeSelectOption(contentTypeName, tableName, propertyName, option) {
  return http.post(`${CONTENT_TYPES_SCHEMA_PATH}/${contentTypeName}/${tableName}/columns/${propertyName}/options`, {
    data: option
  });
}
export function updateContentTypeSelectOption(contentTypeName, tableName, propertyName, option) {
  return http.patch(`${CONTENT_TYPES_SCHEMA_PATH}/${contentTypeName}/${tableName}/columns/${propertyName}/options/${option.id}`, {
    data: {
      name: option.name,
      label: option.label
    }
  });
}
export function deleteContentTypeSelectOption(contentTypeName, tableName, propertyName, option) {
  return http.delete(`${CONTENT_TYPES_SCHEMA_PATH}/${contentTypeName}/${tableName}/columns/${propertyName}/options/${option.id}`);
}