import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "error"];
import { registerQuery, useQuery } from 'data-fetching-client';
import userInfo from 'hub-http/userInfo';
const userInfoQuery = registerQuery({
  fieldName: 'authData',
  args: [],
  fetcher: () => userInfo()
});
const useFetchUserInfo = () => {
  const _useQuery = useQuery(userInfoQuery),
    {
      data,
      error
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  if (error) {
    console.error(error);
  }
  return Object.assign({
    data: data === null || data === void 0 ? void 0 : data.authData,
    error
  }, rest);
};
export default useFetchUserInfo;