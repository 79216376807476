import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { SENTRY_KEYS } from 'campaigns-lib/constants/sentry';
import Raven from 'raven-js';
export const GET_OBJECT_PROPERTIES_FIELD_NAME = 'crmObjectProperties';
const GET_OBJECT_PROPERTIES = registerQuery({
  fieldName: GET_OBJECT_PROPERTIES_FIELD_NAME,
  args: ['objectType'],
  fetcher({
    objectType
  }) {
    return http.post('inbounddb-meta/v1/object-types/batch-get', {
      data: {
        objectTypeIds: [objectType]
      }
    });
  }
});
export function useFetchObjectPropertyDefinitions({
  objectType
}) {
  var _data$GET_OBJECT_PROP;
  const {
    data,
    loading
  } = useQuery(GET_OBJECT_PROPERTIES, {
    variables: {
      objectType
    },
    onError: error => Raven.captureException(error, {
      fingerprint: [SENTRY_KEYS.OBJECT_DEFINITION_FETCH_FAILED],
      tags: {
        lib: 'campaigns-lib'
      }
    })
  });
  return {
    data: data === null || data === void 0 || (_data$GET_OBJECT_PROP = data[GET_OBJECT_PROPERTIES_FIELD_NAME]) === null || _data$GET_OBJECT_PROP === void 0 ? void 0 : _data$GET_OBJECT_PROP[0],
    loading
  };
}