import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "error"];
import { registerQuery, useQuery } from 'data-fetching-client';
import CrmObjectApi from '../api/CrmObjectApi';
const userObjectQuery = registerQuery({
  fieldName: 'userObject',
  args: ['userId', 'propertyNames'],
  fetcher: ({
    userId,
    propertyNames
  }) => CrmObjectApi.getObjectByInternalUserId(userId, propertyNames)
});
const useFetchUserObject = (userId, propertyNames) => {
  var _data$userObject;
  const _useQuery = useQuery(userObjectQuery, {
      variables: {
        userId,
        propertyNames
      },
      skip: !userId,
      fetchPolicy: 'cache-first'
    }),
    {
      data,
      error
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  if (error) {
    console.error(error);
  }
  return Object.assign({
    data: data === null || data === void 0 || (_data$userObject = data.userObject) === null || _data$userObject === void 0 ? void 0 : _data$userObject.results[0],
    error
  }, rest);
};
export default useFetchUserObject;