'use es6';

import { makeAsyncComponent, makeAsyncComponentWithOverlay } from 'ContentEditorUI/components/shared/makeAsyncUtils';
import AsyncMofoFields from 'page-editor-ui/components/AsyncMofoFields';
export const AsyncOnboardingTour = makeAsyncComponent(() => import( /* webpackChunkName: 'OnboardingTour' */'onboarding-tours/components/OnboardingTour'));
export const AsyncCodeMirrorEditorField = makeAsyncComponent(() => {
  return Promise.all([import( /* webpackChunkName: 'CodeMirrorEditorField' */'page-editor-ui/components/LazyCodeMirrorEditorField'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default()).catch(() => {
    throw new Error('Error loading CodeMirror or dependent module');
  });
});
const AsyncHtmlEditorModalContainer = makeAsyncComponentWithOverlay(() => {
  return Promise.all([import( /* webpackChunkName: 'HtmlEditorModalContainer' */'page-editor-ui/components/LazyHtmlEditorModalContainer'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default());
});
const AsyncPagesPreviewModalContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PagesPreviewModalContainer' */'page-editor-ui/containers/PagesPreviewModalContainer'));
const AsyncPageAdvancedSettingsContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PageAdvancedSettingsContainer' */'page-editor-ui/containers/PageAdvancedSettingsContainer'));
const AsyncPageSettingsForMABContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PageSettingsForMABContainer' */'page-editor-ui/containers/PageSettingsForMABContainer'));
const AsyncPageSettingsContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PageSettingsContainer' */'page-editor-ui/containers/PageSettingsContainer'));
const AsyncPageScopesHaveChangedModal = makeAsyncComponent(() => import( /* webpackChunkName: 'PageScopesHaveChangedModal' */'page-editor-ui/components/PageScopesHaveChangedModal'));
const AsyncPartnerMatchingModalContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PartnerMatchingModalContainer' */'page-editor-ui/containers/MatchingModalContainer'));
const AsyncCampaignFieldContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'CampaignFieldContainer' */'ContentEditorUI/containers/settings/CampaignFieldContainer'));
const AsyncCollaborationSidebarContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'collaboration-sidebar-container' */'ContentEditorUI/containers/CollaborationSidebarContainer'));
const AsyncContentGenerationModalContainer = makeAsyncComponentWithOverlay(() => {
  return import( /* webpackChunkName: 'ContentGenerationModalContainer' */'tinymce-plugins/components/ContentGenerationModalContainer');
});
const AsyncPatagoniaRunATestContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PatagoniaRunATestContainer' */'page-editor-ui/components/PatagoniaMABExperimentContainer'));
const AsyncPatagoniaSettingsModalContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PatagoniaPageSettingsModalContainer' */'page-editor-ui/containers/PatagoniaPageSettingsModalContainer'));
const AsyncPatagoniaOnboardingTourModalContainer = makeAsyncComponent(() => import( /* webpackChunkName: 'PatagoniaPageSettingsModalContainer' */'page-editor-ui/containers/PatagoniaOnboardingTourModalContainer'));
const AsyncMicroappOnboardingTour = makeAsyncComponent(() => import( /* webpackChunkName: 'MicroappOnboardingTour' */'page-editor-ui/components/MicroappOnboardingTour'));
const AsyncAiAbTestFlowModal = makeAsyncComponent(() => import( /* webpackChunkName: 'AiAbTestFlowModal' */'page-editor-ui/components/AiAbTestFlowModal'));
const AsyncAiLpThankYouPageReminderModal = makeAsyncComponent(() => import( /* webpackChunkName: 'AiLpThankYouPageReminderModal' */'page-editor-ui/components/AiLpThankYouPageReminderModal'));
const AsyncContentRemixPostPublishModal = makeAsyncComponent(() => import( /* webpackChunkName: 'ContentRemixPostPublishModal' */'page-editor-ui/components/ContentRemixPostPublishWrapper'));
export const asyncComponentsOverrides = {
  extraComponents: {
    PagesPreviewModalContainer: AsyncPagesPreviewModalContainer,
    CampaignFieldContainer: AsyncCampaignFieldContainer,
    CodeMirrorEditorField: AsyncCodeMirrorEditorField,
    CollaborationSidebarContainer: AsyncCollaborationSidebarContainer,
    PageSettingsForMABContainer: AsyncPageSettingsForMABContainer,
    PageAdvancedSettingsContainer: AsyncPageAdvancedSettingsContainer,
    PageSettingsContainer: AsyncPageSettingsContainer,
    PageScopesHaveChangedModal: AsyncPageScopesHaveChangedModal,
    PartnerMatchingModalContainer: AsyncPartnerMatchingModalContainer,
    OnboardingTour: AsyncOnboardingTour,
    MicroappOnboardingTour: AsyncMicroappOnboardingTour,
    PatagoniaSettingsModalContainer: AsyncPatagoniaSettingsModalContainer,
    PatagoniaOnboardingTourModalContainer: AsyncPatagoniaOnboardingTourModalContainer,
    PatagoniaRunATestContainer: AsyncPatagoniaRunATestContainer,
    AiAbTestFlowModal: AsyncAiAbTestFlowModal,
    AiLpThankYouPageReminderModal: AsyncAiLpThankYouPageReminderModal,
    ContentRemixPostPublishModal: AsyncContentRemixPostPublishModal
  },
  extraMofoFields: Object.assign({}, AsyncMofoFields),
  extraTinyMceModals: {
    HtmlEditorModalContainer: AsyncHtmlEditorModalContainer,
    ContentGenerationModalContainer: AsyncContentGenerationModalContainer
  }
};