'use es6';

import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { selectThemeVariantName } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { getIsDataAuthoringModeActive } from 'ContentEditorUI/redux/selectors/instanceEditingSelectors';
import { selectInstanceId } from 'structured-content-lib/redux/selectors/instanceEditSelectors';
export const getStandardFetchCssRequestOptions = state => {
  const contentId = getContentModelId(state);
  const themeVariant = selectThemeVariantName(state);
  const isDataAuthoringModeActive = getIsDataAuthoringModeActive(state);
  const query = themeVariant ? {
    themeVariant
  } : {};
  if (isDataAuthoringModeActive) {
    const instanceId = selectInstanceId(state);
    if (instanceId) {
      query.hubdb_row_id = instanceId;
    }
  }
  return {
    url: `cos-rendering/v1/internal/render/${contentId}/edit/css-assets`,
    query
  };
};