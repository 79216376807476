import { ContentObjectTypes } from './ContentObjectTypes';
export const Namespaces = {
  LANDING_PAGES: 'LANDING_PAGES',
  SITE_PAGES: 'SITE_PAGES',
  BLOG_POSTS: 'BLOG_POSTS',
  KNOWLEDGE_ARTICLES: 'KNOWLEDGE_ARTICLES',
  CASE_STUDIES: 'CASE_STUDIES',
  PODCAST_EPISODES: 'PODCAST_EPISODES',
  CMP_LANDING_PAGES: 'CMP_LANDING_PAGES',
  CMP_SITE_PAGES: 'CMP_SITE_PAGES',
  CMP_BLOG_POSTS: 'CMP_BLOG_POSTS'
};
export const NamespacesToContentObjectTypes = {
  [Namespaces.LANDING_PAGES]: ContentObjectTypes.LANDING_PAGE,
  [Namespaces.SITE_PAGES]: ContentObjectTypes.SITE_PAGE,
  [Namespaces.BLOG_POSTS]: ContentObjectTypes.BLOG_POST,
  [Namespaces.KNOWLEDGE_ARTICLES]: ContentObjectTypes.KNOWLEDGE_ARTICLE,
  [Namespaces.CASE_STUDIES]: ContentObjectTypes.CASE_STUDY,
  [Namespaces.PODCAST_EPISODES]: ContentObjectTypes.PODCAST_EPISODE,
  [Namespaces.CMP_LANDING_PAGES]: ContentObjectTypes.LANDING_PAGE,
  [Namespaces.CMP_SITE_PAGES]: ContentObjectTypes.SITE_PAGE,
  [Namespaces.CMP_BLOG_POSTS]: ContentObjectTypes.BLOG_POST
};