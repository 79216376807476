// Laboratory documentation: https://product.hubteam.com/docs/laboratory/guides/technical-stuff/frontend/coding-a-frontend-experiment.html

import { createClient } from 'laboratory-lib';
import portalIdParser from 'PortalIdParser';
import { getDeviceId, getUtk } from 'usage-tracker-core/common/defaultTrackers';
import experiments from '../../experiments.yaml';
const portalId = portalIdParser.get();
const identifiers = portalId ? {
  portalId,
  other: null,
  utk: null
} : {
  portalId: null,
  other: getDeviceId(),
  utk: getUtk()
};
export const laboratoryClient = createClient({
  quickFetchLabel: 'experiment-treatments',
  identifiers,
  experiments: experiments
});
export const logExposure = treatmentKey => laboratoryClient.logExposure(treatmentKey);