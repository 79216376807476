import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { userInfoSync } from 'hub-http/userInfo';
const GET_BUSINESS_UNITS_FIELD_NAME = 'businessUnits';
const GET_BUSINESS_UNITS = registerQuery({
  fieldName: GET_BUSINESS_UNITS_FIELD_NAME,
  args: [],
  fetcher() {
    return http.get(`/business-units/v1/business-unit-displays/user/dropdown`);
  }
});
export function useFetchBusinessUnits() {
  const {
    user: {
      scopes
    }
  } = userInfoSync();
  const {
    data,
    loading
  } = useQuery(GET_BUSINESS_UNITS, {
    skip: !scopes.includes('business-units-access')
  });
  return {
    data: data === null || data === void 0 ? void 0 : data[GET_BUSINESS_UNITS_FIELD_NAME],
    loading
  };
}