import { keyBy } from '../utils/keyBy';
import { createWorkerInstance } from './createWorkerInstance';
// @ts-expect-error untyped
import { buildFastJsonPatchInstance } from '../vendor/fast-json-patch';
let logPatch = false;
try {
  logPatch = localStorage.getItem('persist-promise:logPatchMetrics') === 'true';
} catch (e) {
  // Do nothing
}
let disableTimeout = false;
export const __TEST_ONLY__disableWorkerTimeout = () => {
  disableTimeout = true;
};
export const __TEST_ONLY__enableWorkerTimeout = () => {
  disableTimeout = false;
};
const emptyFunction = () => {};
export const runWorker = ({
  namespace,
  entityName,
  cacheKey,
  current,
  previous,
  enablePatchDiffing,
  extraProcessing,
  normalizeForPatchDiffing
}) => new Promise((resolve, reject) => {
  const worker = createWorkerInstance(`
(() => {
  var compare = ${enablePatchDiffing ? `(${buildFastJsonPatchInstance})()` : '() => []'};

  var keyBy = ${keyBy};

  var normalize = ${enablePatchDiffing && normalizeForPatchDiffing ? normalizeForPatchDiffing : emptyFunction};

  var extraProcessing = ${extraProcessing || emptyFunction};

  var logPatch = ${logPatch};

  self.onmessage = (e) => {
    try {
      var current = e.data.current;
      var previous = e.data.previous;
      var namespace = e.data.namespace;
      var entityName = e.data.entityName;
      var cacheKey = e.data.cacheKey;

      var extraProcessingResult = extraProcessing(current, previous, { keyBy });

      var normalizedCurrent = normalize(current, { keyBy });

      var normalizedPrevious = normalize(previous, { keyBy });

      const patch = compare(normalizedPrevious, normalizedCurrent);

      if (!patch || !patch.length) {
        self.postMessage({
          type: "DATA",
          patchSizeBytes: 0,
          patchSizePct: 0,
          totalSizeBytes: 0,
          extraProcessingResult
        });
      }

      var encoder = new TextEncoder();

      var patchSizeBytes = encoder.encode(JSON.stringify(patch)).length;
      var currentSizeBytes = encoder.encode(JSON.stringify(normalizedCurrent)).length;

      var patchSizePct = patchSizeBytes / currentSizeBytes;

      if (logPatch && patch && patch.length) {
        const groupName = '[' + namespace + '/' + entityName +'] ' + cacheKey + '\\nPatch size: ' + (patchSizePct * 100).toFixed(2) + '%';
        console.groupCollapsed(groupName);
        console.log(JSON.stringify({
          totalSizeBytes: currentSizeBytes,
          patchSizeBytes,
          patchSizePct,
          patch,
        }, null, 2));
        console.groupEnd(groupName);
      }

      self.postMessage({
        type: "DATA",
        patchSizeBytes,
        patchSizePct,
        totalSizeBytes: currentSizeBytes,
        extraProcessingResult
      });
    } catch (e) {
      self.postMessage({ type: "ERROR", error: e });
    }
  }
})();`);
  let didTerminate = false;
  worker.onmessage = function (e) {
    didTerminate = true;
    worker.terminate();
    if (e.data.type === 'ERROR') {
      reject(e.data.error);
    } else if (e.data.type === 'DATA') {
      resolve(e.data);
    }
  };
  if (!disableTimeout) {
    setTimeout(() => {
      if (!didTerminate) {
        didTerminate = true;
        worker.terminate();
        reject(Error('Worker timeout'));
      }
    }, 60000);
  }
  worker.postMessage({
    current,
    previous,
    namespace,
    entityName,
    cacheKey
  });
});