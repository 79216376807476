import http from 'hub-http/clients/apiClient';
import { useSelector } from 'react-redux';
import { useQueryParams } from 'ContentEditorUI/utils/routingUtils';
import { getIsUngatedForAiLpThankYouPageReminder } from '../redux/selectors/authSelectors';
import { registerQuery, useLazyQuery } from 'data-fetching-client';
import Raven from 'raven-js';
const isValidContentId = contentIdFromParams => typeof contentIdFromParams === 'string' && /^[0-9]+$/.test(contentIdFromParams);
const requestFetchPageById = ({
  contentId
}) => http.get(`cospages/v2/landing-pages/${contentId}`);
const FETCH_PAGE_BY_ID = registerQuery({
  fieldName: 'fetchPageById',
  args: ['contentId'],
  fetcher: requestFetchPageById
});
export const useAiLpThankYouPageReminder = () => {
  const {
    aiLpGeneratedThankYouPageId
  } = useQueryParams();
  const isUngatedForReminder = useSelector(getIsUngatedForAiLpThankYouPageReminder);
  const isThankYouPageValid = isValidContentId(aiLpGeneratedThankYouPageId);
  const isEligibleForThankYouPageReminder = isUngatedForReminder && isThankYouPageValid;
  const logError = ({
    networkError
  }) => {
    Raven.captureMessage('[AILP] - Unable to fetch thank you page publishing state', {
      extra: {
        errorMessage: networkError === null || networkError === void 0 ? void 0 : networkError.message,
        contentId: aiLpGeneratedThankYouPageId
      }
    });
  };
  const [fetchThankYouPage] = useLazyQuery(FETCH_PAGE_BY_ID, {
    variables: {
      contentId: aiLpGeneratedThankYouPageId
    },
    fetchPolicy: 'network-only'
  });
  return {
    fetchThankYouPage,
    logError,
    isEligibleForThankYouPageReminder
  };
};