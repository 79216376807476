import { getUpgradePointPromise, validateBannerUpgradeConfig } from './utils';
export const fetchValidatedBannerUpgradeConfig = upgradeKey => {
  return Promise.all([getUpgradePointPromise({
    upgradeKey,
    delayCall: false,
    includePurchaseMotions: false
  })]).then(([upgradePointResponse]) => {
    if (!upgradePointResponse) return {};
    try {
      validateBannerUpgradeConfig(upgradePointResponse);
    } catch (validationError) {
      console.error(validationError);
      throw validationError;
    }
    return {
      upgradeConfig: upgradePointResponse
    };
  });
};