/**
 * @file This file contains the logic for auto-updating the user timezone in the CRM.
 * For more details, please see the following issue: https://git.hubteam.com/HubSpot/user-setup/issues/1307
 * or reach out to the User Setup team for more information.
 */

import http from 'hub-http/clients/apiClient';
import { setCachedUserObjectTimezone } from './hydrateUserInfo';
function getDeviceTimezone() {
  var _window, _window$DateTimeForma, _window$DateTimeForma2;
  return (_window = window) === null || _window === void 0 || (_window = _window.Intl) === null || _window === void 0 || (_window$DateTimeForma = _window.DateTimeFormat) === null || _window$DateTimeForma === void 0 || (_window$DateTimeForma = _window$DateTimeForma.call(_window)) === null || _window$DateTimeForma === void 0 || (_window$DateTimeForma2 = _window$DateTimeForma.resolvedOptions) === null || _window$DateTimeForma2 === void 0 || (_window$DateTimeForma2 = _window$DateTimeForma2.call(_window$DateTimeForma)) === null || _window$DateTimeForma2 === void 0 ? void 0 : _window$DateTimeForma2.timeZone;
}
function fetchAutoUpdateProperty(userId) {
  return http.post('/crm-search/search', {
    data: {
      objectTypeId: '0-115',
      filterGroups: [{
        filters: [{
          operator: 'EQ',
          property: 'hs_internal_user_id',
          value: String(userId)
        }]
      }],
      count: 1,
      requestOptions: {
        properties: ['hs_timezone_auto_update_enabled'],
        includeAllProperties: false
      }
    }
  }).then(({
    results
  }) => {
    var _results$;
    const autoUpdate = (_results$ = results[0]) === null || _results$ === void 0 ? void 0 : _results$.properties['hs_timezone_auto_update_enabled'].value;
    return autoUpdate === 'true';
  });
}
function updateUserTimezone(timezoneId) {
  return http.put(`user-objects/v1/app/users/`, {
    data: {
      propertyValues: {
        hs_standard_time_zone: timezoneId
      }
    }
  }).then(() => {
    setCachedUserObjectTimezone(timezoneId);
    // eslint-disable-next-line no-console
    console.log('Successfully auto-updated timezone, reloading...');
    window.location.reload();
  }).catch(console.error);
}

/**
 * Check if the device timezone is different from the user timezone.
 * Update the user timezone if the user has auto-update enabled.
 *
 * @param userId - The current User ID (`hs_internal_user_id` on the User object)
 * @param timezoneId - The current User timezone stored in the CRM (e.g. 'America/New_York')
 * @returns Promise<void> - A promise that resolves when the timezone is updated
 */
export function autoUpdateTimezone(userId, timezoneId) {
  const deviceTz = getDeviceTimezone();
  if (!deviceTz) {
    // Can't do anything without device timezone
    // eslint-disable-next-line no-console
    console.error('Failed to get device timezone, aborting...');
    return Promise.resolve();
  }
  if (deviceTz === timezoneId) {
    // No need to update the user timezone
    return Promise.resolve();
  }

  // Need to check if user has auto-update enabled,
  // if so, need to update the user time zone
  return fetchAutoUpdateProperty(userId).then(autoUpdate => {
    if (autoUpdate) {
      return updateUserTimezone(deviceTz);
    }
  }).catch(e => {
    // eslint-disable-next-line no-console
    console.error('Failed to auto-update timezone', e);
  });
}