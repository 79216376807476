'use es6';

import { bindActionCreators } from 'redux';
import { FEATURES } from 'collaboration-sidebar';
import { getSaveData, getCanSave } from 'page-editor-ui/redux/selectors/saveSelectors';
import { getHasPermissionsToLoadEditor } from 'page-editor-ui/redux/selectors/authSelectors';
import { autoSaveDebounced, autoSave, save } from 'page-editor-ui/redux/actions/pageSaveActions';
import SidebarContainer from './containers/SidebarContainer';
import PatagoniaHeaderContainer from 'page-editor-ui/containers/PatagoniaHeaderContainer';
import ExtraApplicationContainerComponents from './components/ExtraApplicationContainerComponents';
import ExtraApplicationContainerEditorWrapper from './components/ExtraApplicationContainerEditorWrapper';
import RootTreeWrapper from './components/RootTreeWrapper';
import rhumbConfig from 'page-editor-ui/rhumb-config.yaml';
import lang from 'i2l?query=sporks!page-editor-ui/lang/en.lyaml';
import routes from './Routes';
import rootReducer from 'page-editor-ui/redux/reducers';
// At some point, pull this out into its own file in page-editor-ui so we can use the StandardRichText
import { asyncComponentsOverrides } from './components/AsyncComponents';
import { getAsyncComponents } from 'ContentEditorUI/components/AsyncComponents';
import { PAGES_API_BASE_URL } from 'page-editor-ui/Constants';
import universalAppReadyMiddleware from 'ContentEditorUI/redux/middleware/universalAppReadyMiddleware';
import { combinedInitialRequestThunk, contentAssistantThunk } from './redux/actions/requestThunks';
import { mapStateToTinyMCEConfig } from './mapStateToTinyMCEConfig';
import { mapStateToCollaborationSidebarConfig } from './mapStateToCollaborationSidebarConfig';
import { getStandardFetchCssRequestOptions } from 'ContentEditorUI/lib/scalableEditor/commonFeatureConfigs';
import setupDevHelpers from './devHelpers';
import { getHasPermissionsToSaveEditor } from './mapStateToCanSavePermission';
import { ContentEditorCopilotCollaborationContextProvider } from 'ContentEditorUI/copilot/ContentEditorCopilotCollaborationContextProvider';
import { indicatorNavigateTo } from 'ContentEditorUI/redux/actions/collaborationSidebarActions';
export const editorConfig = {
  // Passed to the renderer to locate where the request is coming from
  appKey: 'page',
  features: {
    cssAssets: {
      getFetchCssRequestOptions: getStandardFetchCssRequestOptions
    },
    collaborationSidebar: {
      appName: 'page-editor',
      embeddedCommentsProps: {
        appSupportedRoutesRegex: /\/pages\/[0-9]+\/editor\/[0-9]+\/(content)/
      },
      enabledFeatures: [FEATURES.COMMENTS, FEATURES.TASKS, FEATURES.CALENDAR, FEATURES.DEEP_COMMENTING],
      gatedFeatures: {
        [FEATURES.DEEP_COMMENTING]: 'ContentEditorUI:CommentPopOver'
      },
      hideCommentModeAndToggle: true,
      mapStateToCollaborationSidebarConfig,
      getHasPermissionsToSaveEditor,
      mapDispatchToCollaborationSidebarConfig: dispatch => ({
        embeddedCommentsProps: {
          indicatorNavigateTo: bindActionCreators(indicatorNavigateTo, dispatch)
        }
      })
    },
    templateTypes: true,
    shouldUseNopeZonesForAllModules: false,
    shouldFocusSingleStaticModule: false,
    hideLockedEditFormFields: true,
    checkUserAttributes: true,
    breakpoints: true,
    undoRedo: true,
    sections: {
      contentEmbed: true
    },
    smartContent: true,
    aiGeneratedContent: true,
    inlineStyles: true,
    quickSpot: true,
    copilotCollaboration: true,
    moduleInsertion: false
  },
  gatedFeatures: {
    copilotCollaboration: ['Chatspot:Widget:Beta', 'Copilot:Collaborate']
  },
  // Selectors required for the core app to work
  redux: {
    reducers: {
      rootReducer
    },
    selectors: {
      getSaveData,
      getCanSave,
      getHasPermissionsToLoadEditor
    },
    actions: {
      autoSave,
      autoSaveDebounced,
      save
    },
    checkAppReadyMiddleware: universalAppReadyMiddleware(['FETCH_CONTENT_SCHEMA_SUCCEEDED', 'FETCH_MODULE_SCHEMAS_SUCCEEDED', 'GET_CONTENT_ASSISTANT_SETTINGS'])
  },
  components: {
    asyncComponents: getAsyncComponents(asyncComponentsOverrides),
    HeaderExtension: PatagoniaHeaderContainer,
    TabBarExtension: () => null,
    SidebarExtension: SidebarContainer,
    RootTreeWrapper,
    ExtraApplicationContainerComponents,
    ExtraApplicationContainerEditorWrapper,
    CopilotCollaborationContextProvider: ContentEditorCopilotCollaborationContextProvider
  },
  routes,
  infra: {
    rootUrl: '/pages/{portalId}/editor',
    rhumbConfig,
    lang
  },
  api: {
    API_BASE_URL: PAGES_API_BASE_URL,
    // Commenting this out for posterity. Using an empty array ends up fetching a bunch of user attributes
    // we don't need
    // userAttributesToFetch: ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE,

    initialRequests: [combinedInitialRequestThunk, contentAssistantThunk]
  },
  mapStateToTinyMCEConfig,
  setupDevHelpers
};