import { pick } from 'underscore';
import { CUSTOM_MODULE_PROPS } from 'ContentEditorUI/lib/widgetEdit/constants';
import { CategoryToContentType, CategoryToHostTemplateType } from 'ContentEditorUI/lib/categories/constants';
import ContentRoutes from 'ContentUtils/Routes';
const moduleMatchesCategory = (module, categoryName) => {
  const {
    contentTypes = [],
    hostTemplateTypes = []
  } = module;
  if (ContentRoutes.isUngated('CMv2:UseContentTypes')) {
    return contentTypes.indexOf('ANY') !== -1 || contentTypes.indexOf(CategoryToContentType[categoryName]) !== -1;
  }
  return hostTemplateTypes.indexOf('ANY') !== -1 || hostTemplateTypes.indexOf(CategoryToHostTemplateType[categoryName]) !== -1;
};
export const rehydrateModuleSchemasWithTranslations = (moduleSchemasWithTranslations, state) => {
  let newState = state;
  const relevantGroups = ['all', 'allAddable', 'builtInModules', 'customModules', 'globalModules'];
  Object.keys(moduleSchemasWithTranslations).forEach(id => {
    relevantGroups.forEach(key => {
      if (state.hasOwnProperty(key) && moduleSchemasWithTranslations[id].messages) {
        const moduleList = state[key];
        const relevantModuleIdx = Array.isArray(moduleList) ? moduleList.findIndex(v => `${v.id}` === `${id}`) : id;
        const constainsId = Array.isArray(moduleList) ? relevantModuleIdx > -1 : moduleList.hasOwnProperty(id);
        if (constainsId) {
          if (state[key][relevantModuleIdx] && state[key][relevantModuleIdx].messages) {
            let currentMessages = state[key][relevantModuleIdx].messages;
            currentMessages = Object.assign({}, currentMessages, moduleSchemasWithTranslations[id].messages);
            newState = Object.assign({}, newState, {
              [key]: Object.assign({}, newState[key], {
                [relevantModuleIdx]: Object.assign({}, newState[key][relevantModuleIdx], {
                  messages: currentMessages
                })
              })
            });
          }
        }
      }
    });
  });
  return newState;
};

/**
 * Combines new module schemas provided by the `config.globalModules`, `config.customModules`, and `config.usedModuleSchemas` objects with the existing module schema maps
 * It will add the module schema to, or override the existing module schema on, its appropriate module schema maps
 * For instance, a custom module with `isAvailableForNewContent: true` will be added to the `existingCustomModulesMap`, `existingAllModulesMap`, and the `existingAllAddableMap`.
 * The "combiner" follows the combiner options set by `config.useUpdated` and `config.canAddToAddableMap`. By default, these options are set such that any new module schema is added to its respective map
 * You can use these combiner options to tweak how the "combiner" should add module schemas to the module schema maps
 * Returns the new `ModuleSchemaState` following the combination of the new module schemas with the existing module schemas
 * @param {Object} config
 * @param {string} config.categoryName - Category name of the current editable content (ex. 'PAGES')
 * @param {ModuleSchema[]} [config.globalModules=[]] - Global mdoule schemas to add to the existing module schema maps
 * @param {ModuleSchema[]} [config.customModules=[]] - Custom module schemas to add to exising module schema maps
 * @param {ModuleSchemaMap} [config.usedModuleSchemas={}] - Module schemas used in the current content to add to the existing module schema maps
 * @param {ModuleSchemaMap} [config.existingBuiltInModulesMap={}] - The existing builtin modules map that maps the module schema's id to the builtin module schema
 * @param {ModuleSchemaMap} [config.existingGlobalModulesMap={}] - The existing global modules map that maps the module schema's id to the portal's global modules
 * @param {ModuleSchemaMap} [config.existingCustomModulesMap={}] - The existing custom modules map that maps the module schema's id to the portal's custom modules
 * @param {ModuleSchemaMap} [config.existingAllModulesMap={}] - The existing all modules map that maps the module schema's id to the all of the module schema's seen in the combiner
 * @param {ModuleSchemaMap} [config.existingAllAddableMap={}] - The existing all addable modules map that maps the module schema's id to the addable module schema
 * @param {boolean} [config.useUpdated=false] - Combiner option that checks the existing module schema map for the current module schema, and then checks to see if the schema we received is a newer version of the module schema prior to overwritting it to its respective maps. Can be helpful in speeding up the combiner when using cached module schemas
 * @param {boolean} [config.canAddToAddableMap=true] - Combiner option that will prevent the module schema from being added to the `existingAllAddableMap` and `existingBuiltInModulesMap` maps if set to `false`
 * @param {ModuleSchemaState} state
 * @returns {ModuleSchemaState}
 */
export const combineModuleSchemas = ({
  categoryName,
  globalModules = [],
  customModules = [],
  usedModuleSchemas = {},
  existingBuiltInModulesMap = {},
  existingGlobalModulesMap = {},
  existingCustomModulesMap = {},
  existingAllModulesMap = {},
  existingAllAddableMap = {},
  useUpdated = false,
  canAddToAddableMap = true
}, state) => {
  if (Object.keys(usedModuleSchemas).length) {
    customModules = customModules.concat(Object.keys(usedModuleSchemas).map(k => usedModuleSchemas[k]));
  }
  let anythingUpdated = false;

  // Clone all of these objects once at the start (instead of doing it again and again in a loop)
  existingBuiltInModulesMap = Object.assign({}, existingBuiltInModulesMap);
  existingGlobalModulesMap = Object.assign({}, existingGlobalModulesMap);
  existingCustomModulesMap = Object.assign({}, existingCustomModulesMap);
  existingAllModulesMap = Object.assign({}, existingAllModulesMap);
  existingAllAddableMap = Object.assign({}, existingAllAddableMap);
  customModules.forEach(module => {
    const moduleId = `${module.id}`;

    // First check if we already have the newest version of the module in our maps in Redux
    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;

      // If the module is the right type (i.e "PAGE" or "ANY" for the Page Editor),
      // then we want to check whether or not we should add it to our custom modules, builtin,
      // global, and/or addable
      if (moduleMatchesCategory(module, categoryName)) {
        if (module.default) {
          // Default (built-in) modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'preset_widget',
            displayName: module.displayName || module.label
          });
          if (canAddToAddableMap) {
            existingBuiltInModulesMap[moduleId] = m;
          }
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        } else if (module.global) {
          // Global modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'global_widget',
            displayName: module.displayName || module.name
          });
          existingGlobalModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        } else {
          // Custom modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'custom_widget',
            displayName: module.displayName || module.name
          });
          existingCustomModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        }
      } else {
        // Otherwise we still want to add it to the allModulesMap so that we still have the schema for legacy
        // modules that have hostTemplateTypes of NONE
        const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
          content_widget_type: 'custom_widget',
          displayName: module.displayName || module.name
        });
        existingAllModulesMap[moduleId] = m;
      }
    }
  });
  globalModules.forEach(module => {
    const moduleId = `${module.id}`;
    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;

      // Not certain on this typing as I don't have any V1 globals and the API spec
      // doesn't really provide any helpful typings
      // Though I imagine it's _probably_ ok as I'm not sure how often we run into those anymore
      const m = Object.assign({}, module, {
        content_widget_type: 'global_widget',
        displayName: module.displayName || module.label
      });
      existingGlobalModulesMap[moduleId] = m;
      existingAllModulesMap[moduleId] = m;
      if (module.isAvailableForNewContent !== false) {
        existingAllAddableMap[moduleId] = m;
      }
    }
  });
  if (anythingUpdated) {
    return Object.assign({}, state, {
      all: existingAllModulesMap,
      builtInModules: existingBuiltInModulesMap,
      globalModules: existingGlobalModulesMap,
      customModules: existingCustomModulesMap,
      allAddable: existingAllAddableMap
    });
  }
  return state;
};

/**
 * Combines new module schemas provided by the `config.globalModules`, `config.customModules`, and `config.usedModuleSchemas` objects with the existing module schema maps based on the provided `filterFunction`
 * It will add the module schema to, or override the existing module schema on, the appropriate module schema maps for the given module schema, given that the `filterFunction` returns `true`
 * For instance, a custom module with `isAvailableForNewContent: true` will be added to the `existingCustomModulesMap`, `existingAllModulesMap`, and the `existingAllAddableMap`.
 * The "combiner" follows the combiner options set by `config.useUpdated` and `config.canAddToAddableMap`. By default, these options are set such that any new module schema is added to its respective map
 * You can use these combiner options to tweak how the "combiner" should add module schemas to the module schema maps
 * Returns the new `ModuleSchemaState` following the combination of the new module schemas with the existing module schemas
 * @param {Object} config
 * @param {string} config.categoryName - Category name of the current editable content (ex. 'PAGES')
 * @param {ModuleSchema[]} [config.globalModules=[]] - Global mdoule schemas to add to the existing module schema maps
 * @param {ModuleSchema[]} [config.customModules=[]] - Custom module schemas to add to exising module schema maps
 * @param {ModuleSchemaMap} [config.usedModuleSchemas={}] - Module schemas used in the current content to add to the existing module schema maps
 * @param {ModuleSchemaMap} [config.existingBuiltInModulesMap={}] - The existing builtin modules map that maps the module schema's id to the built in module schema
 * @param {ModuleSchemaMap} [config.existingGlobalModulesMap={}] - The existing global modules map that maps the module schema's id to the portal's global modules
 * @param {ModuleSchemaMap} [config.existingCustomModulesMap={}] - The existing custom modules map that maps the module schema's id to the portal's custom modules
 * @param {ModuleSchemaMap} [config.existingAllModulesMap={}] - The existing all modules map that maps the module schema's id to the all of the module schema's seen in the combiner
 * @param {ModuleSchemaMap} [config.existingAllAddableMap={}] - The existing all addable modules map that maps the module schema's id to the addable module schema
 * @param {boolean} [config.useUpdated=false] - Combiner option that checks the existing module schema map for the current module schema, and then checks to see if the schema we received is a new version of the module schema prior to overwritting it to its respective maps. Can be helpful in speeding up the combiner when using cached module schemas
 * @param {boolean} [config.canAddToAddableMap=true] - Combiner option that will prevent the module schema from being added to the `existingAllAddableMap` and `existingBuiltInModulesMap` maps if set to `false`
 * @param {ModuleSchemaState} state
 * @param {(moduleSchema: ModuleSchema, categoryName: CategoryName) => boolean} filterFunction - Filter function that specifies whether or not a module schema should be added to any of the module schema maps
 * @returns {ModuleSchemaState}
 */
export const combineModuleSchemasWithDynamicFilter = ({
  categoryName,
  globalModules = [],
  customModules = [],
  usedModuleSchemas = {},
  existingBuiltInModulesMap = {},
  existingGlobalModulesMap = {},
  existingCustomModulesMap = {},
  existingAllModulesMap = {},
  existingAllAddableMap = {},
  useUpdated = false,
  canAddToAddableMap = true
}, state, filterFunction) => {
  if (Object.keys(usedModuleSchemas).length) {
    customModules = customModules.concat(Object.keys(usedModuleSchemas).map(k => usedModuleSchemas[k]));
  }
  let anythingUpdated = false;

  // Clone all of these objects once at the start (instead of doing it again and again in a loop)
  existingBuiltInModulesMap = Object.assign({}, existingBuiltInModulesMap);
  existingGlobalModulesMap = Object.assign({}, existingGlobalModulesMap);
  existingCustomModulesMap = Object.assign({}, existingCustomModulesMap);
  existingAllModulesMap = Object.assign({}, existingAllModulesMap);
  existingAllAddableMap = Object.assign({}, existingAllAddableMap);
  customModules.forEach(module => {
    const moduleId = `${module.id}`;

    // First check if we already have the newest version of the module in our maps in Redux
    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;

      // If the module is the right type (i.e "PAGE" or "ANY" for the Page Editor),
      // then we want to check whether or not we should add it to our custom modules, builtin,
      // global, and/or addable
      if (filterFunction(module, categoryName)) {
        if (module.default) {
          // Default (built-in) modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'preset_widget',
            displayName: module.displayName || module.label
          });
          if (canAddToAddableMap) {
            existingBuiltInModulesMap[moduleId] = m;
          }
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        } else if (module.global) {
          // Global modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'global_widget',
            displayName: module.displayName || module.name
          });
          existingGlobalModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        } else {
          // Custom modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'custom_widget',
            displayName: module.displayName || module.name
          });
          existingCustomModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;
          if (module.isAvailableForNewContent !== false && canAddToAddableMap) {
            existingAllAddableMap[moduleId] = m;
          }
        }
      } else {
        // Otherwise we still want to add it to the allModulesMap so that we still have the schema for legacy
        // modules that have hostTemplateTypes of NONE
        const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
          content_widget_type: 'custom_widget',
          displayName: module.displayName || module.name
        });
        existingAllModulesMap[moduleId] = m;
      }
    }
  });
  globalModules.forEach(module => {
    const moduleId = `${module.id}`;
    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;

      // Not certain on this typing as I don't have any V1 globals and the API spec
      // doesn't really provide any helpful typings
      // Though I imagine it's _probably_ ok as I'm not sure how often we run into those anymore
      const m = Object.assign({}, module, {
        content_widget_type: 'global_widget',
        displayName: module.displayName || module.label
      });
      existingGlobalModulesMap[moduleId] = m;
      existingAllModulesMap[moduleId] = m;
      if (module.isAvailableForNewContent !== false) {
        existingAllAddableMap[moduleId] = m;
      }
    }
  });
  if (anythingUpdated) {
    return Object.assign({}, state, {
      all: existingAllModulesMap,
      builtInModules: existingBuiltInModulesMap,
      globalModules: existingGlobalModulesMap,
      customModules: existingCustomModulesMap,
      allAddable: existingAllAddableMap
    });
  }
  return state;
};