import { APP_STATUS } from 'ContentEditorUI/redux/constants';
import { getAppStatus } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
// @ts-expect-error Untyped module
import { getContentModelPageExpiryEnabled } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
// @ts-expect-error Untyped module
import { getContentModelPageExpiryDate } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-expect-error Untyped module
import { save } from 'page-editor-ui/redux/actions/pageSaveActions';
// @ts-expect-error Untyped module
import { updateExpiryDate } from 'ContentEditorUI/redux/actions/baseContentModelActions';

// TODO: Remove - this is a temporary fix for invalid state where
// pages have `pageExpiryDate` set but `pageExpiryEnabled` is false.
// This should fix it on demand if the user encounters it before our job updates
const FixInvalidPageExpiryDateController = () => {
  const [invalidStateFixed, setInvalidStateFixed] = useState(false);
  const appStatus = useSelector(getAppStatus);
  const pageExpiryDate = useSelector(getContentModelPageExpiryDate);
  const pageExpiryEnabled = useSelector(getContentModelPageExpiryEnabled);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!invalidStateFixed && appStatus === APP_STATUS.READY && !pageExpiryEnabled && pageExpiryDate) {
      dispatch(updateExpiryDate(null));
      dispatch(save());
      setInvalidStateFixed(true);
    }
  }, [appStatus, dispatch, invalidStateFixed, pageExpiryDate, pageExpiryEnabled]);
  return null;
};
export default FixInvalidPageExpiryDateController;