import httpClient from 'hub-http/clients/apiClient';
import { parse, stringify } from 'hub-http/helpers/params';
import { createRpcClientV2 } from 'rpc-client-utils';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { getUpgradeConfigWithPurchaseMotionsRpc } from 'upgrade-management-service-types';
import { i18nKeysByUpgradeProduct } from 'ui-addon-upgrades/_core/common/adapters/getProductNameText';
import { sourceKeys, sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { getIframeModalHost } from '../_core/utils/getIframeModalHost';
import { submitInteraction } from 'self-service-api/api/submitInteraction';
import { BUNDLE } from 'self-service-api/constants/UpgradeProducts';
// @ts-expect-error dependency missing types
import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import { trackFailedApiNameMap } from '../_core/utils/metricUtils';
import { UPGRADE_BANNER_TYPES } from './types';
import { isRpcError } from '../_core/utils/errors';
export let UpgradePointStatus;
(function (UpgradePointStatus) {
  UpgradePointStatus["STALE"] = "STALE";
  UpgradePointStatus["ACTIVE"] = "ACTIVE";
  UpgradePointStatus["ARCHIVED"] = "ARCHIVED";
  UpgradePointStatus["PLANNED"] = "PLANNED";
})(UpgradePointStatus || (UpgradePointStatus = {}));
const upgradePointPromises = {};

// viewedUpgradePointsCache is a module cache that stores whether a user has
// viewed an upgrade point, via a unique upgradeKey. This allows to us to
// prevent usage trackers from firing more than once per app session.
export const viewedUpgradePointsCache = {};
export const updateViewedUpgradePointsCache = (upgradeKey, onCacheUpdate) => {
  if (!viewedUpgradePointsCache[upgradeKey]) {
    viewedUpgradePointsCache[upgradeKey] = true;
    if (onCacheUpdate) onCacheUpdate();
  }
};
export function getApp() {
  return window.location.pathname.split('/')[1];
}
export function getScreen() {
  return window.location.pathname;
}
export function getHubAndTier(upgradeProduct) {
  return {
    hub: upgradeProduct.split('-')[0],
    tier: upgradeProduct.split('-')[1]
  };
}
export function getHubAndTierFromApiName(apiName) {
  const hub = apiName.split('-')[0];
  const tier = apiName.split('-')[2];
  return {
    hub: hub === 'crm' ? 'suite' : hub,
    tier: !tier && hub === 'hubspot' ? 'free' : tier // free tools api name has no tier
  };
}
function _getHubAndTierProperties({
  upgradeProduct,
  apiName
}) {
  if (apiName) return getHubAndTierFromApiName(apiName);
  if (!upgradeProduct || upgradeProduct === BUNDLE) {
    return {
      hub: null,
      tier: null
    };
  } else {
    return getHubAndTier(upgradeProduct);
  }
}
export function verifyMessage(ref, event) {
  const node = ref.current;
  return node && node.contentWindow === event.source;
}
export function matchMessage(event, message) {
  return event.data === message;
}
export function createFrameSrc({
  upgradeKey,
  app,
  screen,
  portalId,
  params
}) {
  const formattedParams = params ? `&${params}` : '';
  return `${getIframeModalHost()}/ums-modal/${portalId}/${upgradeKey}?app=${app}&screen=${screen}${formattedParams}`;
}
export function getFrameSrc(upgradeKey) {
  return createFrameSrc({
    upgradeKey,
    app: getApp(),
    screen: getScreen(),
    portalId: PortalIdParser.get(),
    params: stringify(parse(window.location.search.substring(1)))
  });
}
export function fetchUpgradePoint(upgradeKey, includePurchaseMotions = true) {
  if (!upgradeKey) {
    return Promise.resolve();
  }
  const rpcClient = createRpcClientV2({
    hubHttpClient: httpClient
  });
  return rpcClient.call(getUpgradeConfigWithPurchaseMotionsRpc, {
    pathParameters: {
      upgradeKey
    },
    queryParameters: {
      includePurchaseMotions
    }
  }).catch(error => {
    // 0 indicates user aborted requests, which we don't want to log as errors
    if (!isRpcError(error) && error.cause.status !== 0) {
      const message = error.status === 404 ? `upgradeKey ${upgradeKey} may not exist in the UMS.` : 'UpgradeManagement API Failure';
      Raven.captureException(new Error('Unexpected error during upgrade config RPC'), {
        extra: {
          message
        }
      });
    }
    throw error;
  });
}

// We delay the api call to give performance priority to the consuming app.
export function delayUntilIdle(callback) {
  window.setTimeout(callback, 5000);
}
export function getUpgradePointPromise({
  upgradeKey,
  delayCall = true,
  // the upgrade config isn't needed right away so delay loading it to improve page performance
  includePurchaseMotions
}) {
  if (upgradePointPromises[upgradeKey]) {
    return upgradePointPromises[upgradeKey];
  }
  const upgradePointPromise = new Promise(resolve => {
    if (delayCall) {
      delayUntilIdle(() => {
        resolve(fetchUpgradePoint(upgradeKey, includePurchaseMotions));
      });
    } else {
      resolve(fetchUpgradePoint(upgradeKey, includePurchaseMotions));
    }
  });
  upgradePointPromises[upgradeKey] = upgradePointPromise;
  return upgradePointPromise;
}
export const getRepName = (repInfo = {}) => {
  if (repInfo && repInfo.name) {
    return repInfo.name;
  }
  return 'Not assigned';
};
export function getUpgradeConfigTrackingProperties(upgradeConfig) {
  return {
    app: getApp(),
    screen: getScreen(),
    feature: upgradeConfig.featureStoreFeature,
    upgradeKey: upgradeConfig.upgradeKey,
    pointType: upgradeConfig.pointType
    //we intentionally don't track upgradeProduct and apiName at the level where we fetch the upgrade config.
  };
}
export function getUpgradeDataTrackingProperties(upgradeData) {
  const apiNameMaybe = upgradeData.apiName || UpgradeProductToApiNameMap[upgradeData.upgradeProduct];
  const {
    hub,
    tier
  } = _getHubAndTierProperties({
    upgradeProduct: upgradeData.upgradeProduct,
    apiName: apiNameMaybe
  });
  return {
    app: upgradeData.app,
    screen: upgradeData.screen,
    uniqueId: upgradeData.uniqueId,
    upgradeProduct: upgradeData.upgradeProduct,
    apiName: apiNameMaybe,
    source: upgradeData.source,
    upgradeKey: upgradeData.upgradeKey,
    pointType: upgradeData.pointType,
    //the feature defined in UpgradeData is equivalent to the featureStoreFeature of the UpgradeConfig
    feature: upgradeData.feature,
    upgradeSource: upgradeData.upgradeSource,
    hub,
    tier
  };
}
export const getSource = (app, screen, uniqueId) => `${app}-${screen}-${sources[sourceKeys.GENERAL]}-${uniqueId}`;
export const getBannerI18nTitleKey = (titleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${titleI18nPrefix}.${featureTranslationKey}`)) {
    return `${titleI18nPrefix}.${featureTranslationKey}`;
  }
  return `${titleI18nPrefix}.placeholder`;
};

// remove branding banners have static subtext and don't call this function
export const getBannerI18nSubTitleKey = (subtitleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${subtitleI18nPrefix}.${featureTranslationKey}`)) {
    return `${subtitleI18nPrefix}.${featureTranslationKey}`;
  }
  return `${subtitleI18nPrefix}.placeholder`;
};
export const getProductI18nName = (subtitleI18nPrefix, upgradeProduct) => {
  const keyByProduct = i18nKeysByUpgradeProduct[upgradeProduct];
  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }
  return null;
};
export const warnArchivedUpgradePointUse = upgradeKey => {
  console.warn(`Upgrade point '${upgradeKey}' is archived, and should not be used in-app. If this upgrade point should not be archived, it can be restored through the UMS at https://tools.hubteamqa.com/ums/upgrade-points/${upgradeKey}.`);
};
export const mapPurchaseMotionApiName = (canViewNewSeats = false, upgradeProduct, source, apiName) => {
  if (canViewNewSeats && !apiName) {
    if (UpgradeProductToApiNameMap[upgradeProduct]) {
      return UpgradeProductToApiNameMap[upgradeProduct];
    } else {
      trackFailedApiNameMap(source, 'no api name', upgradeProduct);
    }
  }
  return apiName;
};
export const trackPotentialBannerInteraction = (pointType, upgradeKey) => {
  if (pointType && upgradeKey && Object.keys(UPGRADE_BANNER_TYPES).includes(pointType)) {
    return submitInteraction(upgradeKey);
  }
  return Promise.resolve();
};
export function validateCommonUpgradeConfig(upgradeConfig) {
  if (!upgradeConfig.apiNames || upgradeConfig.apiNames.length === 0) {
    throw Error('Expected apiNames to be defined');
  }
}
export function validateBannerUpgradeConfig(upgradeConfig) {
  validateCommonUpgradeConfig(upgradeConfig);
  if (!upgradeConfig.featureTranslationKey) {
    throw Error(`Expected featureTranslationKey to be defined for ${upgradeConfig.upgradeKey}`);
  }
}
export function validateModalUpgradeConfig(
// temp inclusion of UpgradeConfig to support consumers
upgradeConfig) {
  validateCommonUpgradeConfig(upgradeConfig);
  if (!upgradeConfig.title) {
    throw Error('Expected title to be defined');
  }
  if (!upgradeConfig.subtitle) {
    throw Error('Expected subtitle to be defined');
  }
  if (!upgradeConfig.visualType) {
    throw Error('Expected visualType to be defined');
  }
  if (!upgradeConfig.visualKey) {
    throw Error('Expected visualKey to be defined');
  }
}

/**
 * Unit and integration testing only
 */
function resetUpgradePointCaches() {
  Object.keys(upgradePointPromises).forEach(upgradeKey => {
    delete upgradePointPromises[upgradeKey];
  });
  Object.keys(viewedUpgradePointsCache).forEach(upgradeKey => {
    delete viewedUpgradePointsCache[upgradeKey];
  });
}
export const __TEST_UTILS__ = {
  resetUpgradePointCaches
};