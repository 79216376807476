'use es6';

import { Component } from 'react';
import { connect } from 'react-redux';
import Raven from 'raven-js';
import PropTypes from 'prop-types';
import http from 'hub-http/clients/noAuthApiClient';
import { getPreviewDomain } from 'ContentEditorUI/redux/selectors/iframesSelectors';
import { EditorMetricsTracker } from 'ContentEditorUI/utils/metricsTracker';
const mapStateToProps = state => {
  return {
    basePreviewIframeUrl: getPreviewDomain(state)
  };
};
class PreviewDomainDebugger extends Component {
  componentDidMount() {
    const {
      basePreviewIframeUrl
    } = this.props;
    setTimeout(() => {
      http.get(`https://${basePreviewIframeUrl}/_hcms/status`, {
        timeout: 15000
      }).catch(() => {
        const error = new Error("Can't reach preview domain");
        Raven.captureException(error, {
          extra: {
            previewDomain: basePreviewIframeUrl
          }
        });

        // Also report to HubSpots internal metrics service
        EditorMetricsTracker.counter('Preview Domain Error').increment();
      });
    }, 5000);
  }
  render() {
    return null;
  }
}
PreviewDomainDebugger.propTypes = {
  basePreviewIframeUrl: PropTypes.string.isRequired
};
export default connect(mapStateToProps)(PreviewDomainDebugger);