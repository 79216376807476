import { FETCH_LAYOUT_DATA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const layoutDataReducer = (state = {}, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_LAYOUT_DATA_SUCCEEDED:
      return Object.assign({}, state, payload);
    default:
      {
        return state;
      }
  }
};
export default layoutDataReducer;