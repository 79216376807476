// Taken from reselect: https://github.com/reduxjs/reselect/blob/master/src/types.ts#L106-L107

// Helper to retain plain object "immutablility" while mapping over all values
export const mapOverObject = (object, func) => {
  let result;
  Object.keys(object).forEach(key => {
    const newValue = func(object[key], key);
    if (newValue !== undefined) {
      if (result === undefined) {
        // On first changed value, clone the original object to return as result
        result = Object.assign({}, object);
      }
      result[key] = newValue;
    }
  });
  if (result) {
    return result;
  }

  // If there never were any changes return the original object
  return object;
};
export const filterObjectValues = (object, func) => {
  const result = {};
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (func(value, key) === true) {
      result[key] = value;
    }
  });
  return result;
};

// Cleaner way to do the invert, but it failed to build in Blazar
// export type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
//   [Key in keyof T as T[Key]]: Key;
// };

// Taken from https://private.hubteam.com/opengrok/xref/all/git.hubteam.com-HubSpot-automation-ui-core%23master/automation-ui-types/static-1.9027/js/utils.ts#45
export const invertObjectKeysAndValues = obj => {
  const invertedObject = {};
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    invertedObject[value] = key;
  }
  return invertedObject;
};
export const isEmptyObject = obj => {
  return obj != null && Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Flattens arrays (deeply) nested inside objects. Note, does not flatten nested arrays
// I think `any[]` might be the best way to type the result for now
// since it can take any data in an object and flatten it into an array
export const deepFlattenArraysInObject = (val, result = []) => {
  if (val == null) {
    return result;
  } else if (Array.isArray(val)) {
    return result.concat(val);
  } else if (typeof val === 'object') {
    for (const key of Object.keys(val)) {
      result = deepFlattenArraysInObject(val[key], result);
    }
  } else {
    result = result.concat([val]);
  }
  return result;
};