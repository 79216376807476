import { makeFetchTypeMetadataEntries } from './frameworkBuilderReadApi';
import { keyEntriesByFQN, recombineAppSettingTypeMap } from './keyEntriesByAppSettings';
import { isEarlyCacheReturnEnabled, makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntryFetchKey } from 'framework-data-schema-quick-fetch';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { wrapPromise } from 'persist-promise/wrapPromise';
const defaultToRequestCacheKey = makeTypeMetadataEntryFetchKey;
const defaultToOperationCacheKey = ({
  appSettingNames
}) => makeTypeMetadataEntriesFetchKey({
  appSettingNames
});
const segmentKey = key => {
  const match = key.match(defaultToRequestCacheKey({
    appSettingName: '(.*)'
  }));
  return match && match[1] || null;
};
const defaultAsyncTypeMetadataRequestCache = createPersistedAsyncCache({
  cacheName: 'typeMetadata',
  segmentKey
});

// Debounces the fetch calls by a tiny amount to ensure we batch requests together,
// but presents the standard interface to the caller.
const fetchTypeMetadataEntries = makeFetchTypeMetadataEntries();
const persistedTypeMetadataPromise = wrapPromise({
  namespace: 'FDSR',
  entityName: 'typeMetadata',
  deepFreeze: true,
  toCacheKey: makeTypeMetadataEntryFetchKey,
  fetch: fetchTypeMetadataEntries,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: segmentKey
  }
});
const defaultTypeMetadataOperationCache = createInMemoryCache({
  cacheName: 'typeMetadata-ops'
});
export const makeTypeMetadataClient = ({
  httpClient,
  usePersistPromise,
  requestCache = defaultAsyncTypeMetadataRequestCache,
  operationCache = defaultTypeMetadataOperationCache,
  persistedPromise = persistedTypeMetadataPromise,
  toRequestCacheKey = defaultToRequestCacheKey,
  toOperationCacheKey = defaultToOperationCacheKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      if (usePersistPromise) {
        persistedPromise.debug();
      } else {
        requestCache.printDebug();
      }
      operationCache.printDebug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), operationCache.clear(), persistedPromise.clearCache()]);
    },
    /**
     * Gets all type metadata entries for the requested app settings.
     *
     * @param options.appSettingNames An array of app settings to get type metadata entries for
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to the type metadata entries, or null if the data could not be found.
     */
    get: ({
      appSettingNames,
      __isComposed = false
    }) => {
      var _ref;
      if (!__isComposed) {
        Metrics.counter('typeMetadata.get', {
          usePersistedPromise: `${usePersistPromise}`
        }).increment();
      }
      return (_ref = operationCache.readThrough({
        cacheKey: toOperationCacheKey({
          appSettingNames
        }),
        loadValue: async () => {
          const results = await Promise.allSettled(appSettingNames.map(appSettingName => usePersistPromise ? persistedPromise.makeFetchWithOpts({
            allowEagerCacheReturn: isEarlyCacheReturnEnabled()
          })({
            appSettingName,
            httpClient
          }) : requestCache.readThrough({
            cacheKey: toRequestCacheKey({
              appSettingName
            }),
            loadValue: () => fetchTypeMetadataEntries({
              appSettingName,
              httpClient
            })
          })));
          if (
          // This is unnecessary with the second case, but makes TS happy
          results[0].status === 'rejected' && results.every(result => result.status === 'rejected')) {
            // If all promises rejected, grab the first error and throw it to give *something* to the caller.
            throw results[0].reason;
          }

          // Otherwise, if any succeded, we'll merge what we do have into a map.
          const combinedAppSettings = recombineAppSettingTypeMap(results, appSettingNames);

          // And finally, invert the map to key by FQN (which is what callers expect)
          return keyEntriesByFQN(combinedAppSettings);
        }
      })) !== null && _ref !== void 0 ? _ref : makeLoadFailed();
    }
  };
  return Promise.resolve(client);
};