import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
// BE AI-gen job statuses:
const NEW = 'NEW';
const PENDING = 'PENDING';
const PROCESSING = 'PROCESSING';
const SUCCEEDED = 'SUCCEEDED';
const FAILED_RETRY = 'FAILED_RETRY';
const FAILED_FOREVER = 'FAILED_FOREVER';
const FAILED_DO_NOT_RETRY = 'FAILED_DO_NOT_RETRY';
export const AiAbTestRequestStatuses = {
  NEW,
  PENDING,
  PROCESSING,
  SUCCEEDED,
  FAILED_RETRY,
  FAILED_FOREVER,
  FAILED_DO_NOT_RETRY
};
// Loading constants:
export const PROGRESS_TOTAL = 100;
export const PROGRESS_INCREMENT = PROGRESS_TOTAL / 2;
export const DELAY_BETWEEN_PROGRESS_INCREMENTS = 10000; // 10 seconds
export const MAX_LOAD_TIMEOUT = 180000; // 3 minutes

// New route, requires AIComponents:AISettings gate
export const AI_SETTINGS_URL = `${getFullUrl('app')}/ai-settings/${PortalIdParser.get()}`;

// FlowErrorTypes
const MANUAL_TIMEOUT = 'MANUAL_TIMEOUT';
const NETWORK_ERROR = 'NETWORK_ERROR';
const UNKNOWN = 'UNKNOWN';
export const AiAbTestFlowErrorTypes = {
  MANUAL_TIMEOUT,
  NETWORK_ERROR,
  UNKNOWN
};