import { FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED, FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED, FETCH_HUBDB_TABLES_SUCCEEDED, FETCH_DATA_QUERIES_SUCCEEDED, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED, FETCH_CRM_OBJECT_TYPES_SUCCEEDED, FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED
// @ts-expect-error not yet migrated
} from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_DOMAIN } from 'ContentEditorUI/redux/actions/actionTypes';
import withBaseSettings from 'ContentEditorUI/redux/reducers/settings/withBaseSettings';
import produce from 'immer';
const initialState = {
  featuredImageThumbnailUrls: {}
};
const pageSettingsReducer = (state = initialState, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED:
      draft.availablePageStylesheets = payload;
      return draft;
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content,
          settings
        } = response;
        return Object.assign(draft, {
          domain: content.resolvedDomain,
          domainStylesheets: settings.attachedStylesheets,
          masterPagesForMultiLang: [],
          // Save this without any parsing (we use the simple name in the content model until save/fetch)
          originalDynamicPageSourceValue: content.dynamicPageDataSourceId
        });
      }
    case FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED:
      return Object.assign(draft, {
        templateStylesheets: payload.layout_attached_stylesheets,
        domainStylesheets: payload.domain_attached_stylesheets
      });
    case FETCH_HUBDB_TABLES_SUCCEEDED:
      draft.availableHubDbTables = payload;
      return draft;
    case FETCH_DATA_QUERIES_SUCCEEDED:
      draft.availableDataQueries = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_DOMAIN:
      draft.domain = payload.domain;
      return draft;
    case FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED:
      draft.featuredImageThumbnailUrls = payload;
      return draft;
    case FETCH_CRM_OBJECT_TYPES_SUCCEEDED:
      draft.availableCrmObjectTypes = payload;
      return draft;
    case FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED:
      draft.crmObjectSchema = payload;
      return draft;
    default:
      return draft;
  }
});
export default withBaseSettings(pageSettingsReducer);