'use es6';

import { createSelector } from 'reselect';
import { getModuleImages } from 'ContentEditorUI/utils/helpers';
import { getModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getContentSettings } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getUploadedFeaturedImages } from 'ContentEditorUI/redux/selectors/uploadedFeaturedImagesSelector';
import { unionSets } from 'ContentEditorUI/utils/set';
import { mapValuesToJS } from '../../utils/dataHelpers';
export const getImagesFromModules = createSelector([getModules, getContentSettings], (modules, settings) => getModuleImages(mapValuesToJS(modules), settings.logoSrc));
export const getModuleAndUploadedImages = createSelector([getImagesFromModules, getUploadedFeaturedImages], (moduleImages, uploadedImages) => {
  if ('union' in moduleImages) {
    return moduleImages.union(uploadedImages);
  }
  return unionSets(moduleImages, uploadedImages);
});