import AudienceAccessTypes from '../constants/AudienceAccessTypes';
import { PublicAccessRuleTypes } from '../constants/PublicAccessRuleTypes';

/**
 * @deprecated import from ui-memberships-settings-lib instead
 * https://git.hubteam.com/HubSpot/memberships-settings-ui/tree/master/ui-memberships-settings-lib#constants
 */

/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export const LIST_MEMBERSHIP_ACCESS_TYPES = [AudienceAccessTypes.SPECIFIC_CONTACTS, AudienceAccessTypes.SSO_WITH_SPECIFIC_CONTACTS];
/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export const getAudienceAccessType = ({
  password,
  publicAccessRulesEnabled,
  publicAccessRules = []
}) => {
  if (publicAccessRulesEnabled) {
    if (publicAccessRules && publicAccessRules.length) {
      const accessType = publicAccessRules[0].type;
      if (accessType === PublicAccessRuleTypes.SSO_LOGIN) {
        return AudienceAccessTypes.SSO;
      }
      if (accessType === PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP) {
        return AudienceAccessTypes.ACCESS_GROUP_MEMBERSHIP;
      }
    }
    return AudienceAccessTypes.SPECIFIC_CONTACTS;
  } else if (password) {
    return AudienceAccessTypes.PASSWORD;
  }
  return AudienceAccessTypes.ALL;
};

/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export const getAudienceAccessTypeForDisplay = (content, membershipSsoEnabled = false) => {
  const accessType = getAudienceAccessType(content);
  if (accessType === AudienceAccessTypes.SPECIFIC_CONTACTS && membershipSsoEnabled) {
    return AudienceAccessTypes.SSO_WITH_SPECIFIC_CONTACTS;
  }
  if (accessType === AudienceAccessTypes.ACCESS_GROUP_MEMBERSHIP && membershipSsoEnabled) {
    return AudienceAccessTypes.SSO_WITH_ACCESS_GROUPS;
  }
  return accessType;
};
const makeParseAudienceAccessRuleIds = ruleTypes => publicAccessRules => {
  if (!publicAccessRules) {
    return [];
  }
  const result = [];
  publicAccessRules.reduce((acc, rule) => {
    const {
      ids,
      type
    } = rule;
    if (ids && ruleTypes.includes(type)) {
      ids.forEach(id => {
        if (!acc.includes(id)) {
          acc.push(id);
        }
      });
    }
    return acc;
  }, result);
  return result;
};

/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export const parseAudienceAccessLists = makeParseAudienceAccessRuleIds([PublicAccessRuleTypes.LIST_MEMBERSHIP, PublicAccessRuleTypes.SSO_LOGIN]);

/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export const getMembershipSsoEnabledForDomain = (domain, portalSettings) => {
  const membershipSsoEnabled = portalSettings['bodyOverrideByDomain'] && portalSettings['bodyOverrideByDomain'][domain] && portalSettings['bodyOverrideByDomain'][domain]['membershipSsoEnabled'];
  const membershipJwtEnabled = portalSettings['bodyOverrideByDomain'] && portalSettings['bodyOverrideByDomain'][domain] && portalSettings['bodyOverrideByDomain'][domain]['membershipJwtEnabled'];
  return membershipSsoEnabled || membershipJwtEnabled;
};