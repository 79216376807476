export const COMMON_OPERATORS = Object.freeze({
  EMPTY: 'EMPTY',
  EQUAL: 'EQUAL',
  NOT_EMPTY: 'NOT_EMPTY',
  NOT_EQUAL: 'NOT_EQUAL'
});
export const STRING_OPERATORS = Object.freeze(Object.assign({}, COMMON_OPERATORS, {
  MATCHES_REGEX: 'MATCHES_REGEX'
}));
export const NUMBER_OPERATORS = Object.freeze(Object.assign({}, COMMON_OPERATORS, {
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  LESS_THAN: 'LESS_THAN'
}));
export const ALL_OPERATORS = Object.freeze(Object.assign({}, COMMON_OPERATORS, STRING_OPERATORS, NUMBER_OPERATORS));
export const ACCESS_OPERATORS = Object.freeze({
  HAS_ALL: 'HAS_ALL',
  HAS_ANY: 'HAS_ANY',
  HAS_NONE: 'HAS_NONE'
});
export const VISIBILITY_RULES = Object.freeze({
  SIMPLE: 'SIMPLE',
  ADVANCED: 'ADVANCED'
});
export const BOOLEAN_OPERATORS = Object.freeze({
  AND: 'AND',
  OR: 'OR'
});