import ApiNameToUpgradeProductMap from 'self-service-api/constants/ApiNameToUpgradeProductMap';
import { getApp, getScreen, getSource } from '../../ums/utils';
export const getUpgradeDataFromUpgradeConfig = upgradeConfig => {
  const app = getApp();
  const screen = getScreen();
  const source = getSource(app, screen, upgradeConfig.upgradeKey);
  const apiName = 'bestFitApiNameForPurchase' in upgradeConfig ? upgradeConfig.bestFitApiNameForPurchase : upgradeConfig.apiNames[0];
  return {
    app,
    screen,
    source,
    upgradeProduct: ApiNameToUpgradeProductMap[apiName],
    apiName,
    apiNames: upgradeConfig.apiNames,
    uniqueId: upgradeConfig.upgradeKey,
    pointType: upgradeConfig.pointType,
    status: upgradeConfig.status,
    feature: upgradeConfig.featureStoreFeature,
    upgradeKey: upgradeConfig.upgradeKey
  };
};