import { registerMutation, useMutation } from 'data-fetching-client';
import UserObjectsApi from '../api/UserObjectsApi';
const updateUserObjectPropertiesQuery = registerMutation({
  fieldName: 'updateUserObjectProperties',
  args: ['properties'],
  fetcher: ({
    properties
  }) => UserObjectsApi.updateUserProperties(properties)
});
export const useUpdateUserObjectProperties = () => {
  const [updateUserObjectProperties, result] = useMutation(updateUserObjectPropertiesQuery);
  if (result.error) {
    console.error(result.error);
  }
  return {
    updateUserObjectProperties,
    result
  };
};