import { getFromIDB, removeAllKeysWithPrefix, removeFromIDB, setInIDB } from '../persistence/indexeddb';
import { Metrics } from '../metrics';
import { runWorker } from '../worker/runWorker';
import { createInMemoryCache } from './createInMemoryCache';
import enviro from 'enviro';
import { trackDataChanges } from '../worker/trackDataChanges';
import { withQuickLoad } from '../withQuickLoad';
import { toNamespacedKey } from '../utils/toNamespacedKey';
import userInfo from 'hub-http/userInfo';
import { freeze } from './freeze';
const defaultResolvePortalAndUserId = () => userInfo({
  ignoreRedirect: true
}).then(({
  portal: {
    portal_id
  },
  user: {
    user_id
  }
}) => ({
  portalId: portal_id,
  userId: user_id
}));
const isDeployed = enviro.deployed();

// Default to 1 day of staleness tolerance
export const DEFAULT_STALENESS_CUTOFF = 1000 * 60 * 60 * 24;
export function createPersistedCache({
  namespace,
  entityName,
  stalenessCutoff = DEFAULT_STALENESS_CUTOFF,
  allowEagerCacheReturn: topLevelEagerConfig = false,
  deepFreeze = false,
  resolvePortalAndUserId = defaultResolvePortalAndUserId,
  metricsConfig: {
    enablePatchDiffing = false,
    normalizeForPatchDiffing = input => input,
    convertKeyToMetricsDimension = () => null,
    additionalProcessing
  } = {}
}) {
  const __cache = createInMemoryCache({
    cacheName: toNamespacedKey(namespace, entityName)
  });
  return Object.assign({}, __cache, {
    clear: () => {
      __cache.clear();
      removeAllKeysWithPrefix({
        prefix: toNamespacedKey(namespace, entityName),
        resolvePortalAndUserId
      }).catch(() => {
        // Do nothing
      });
      return true;
    },
    delete: cacheKey => {
      __cache.delete(cacheKey);
      removeFromIDB({
        key: toNamespacedKey(namespace, entityName, cacheKey),
        resolvePortalAndUserId
      }).catch(() => {
        // Do nothing
      });
      return true;
    },
    set: (cacheKey, value) => {
      __cache.set(cacheKey, value.then(result => deepFreeze ? freeze(result) : result));
      value.then(result => setInIDB({
        key: toNamespacedKey(namespace, entityName, cacheKey),
        value: {
          data: result,
          storedAt: Date.now()
        },
        resolvePortalAndUserId
      })).catch(() => {
        // Do nothing
      });
    },
    readThrough: ({
      cacheKey,
      fetchValue,
      opts
    }) => __cache.readThrough({
      cacheKey,
      opts,
      fetchValue: () => {
        let currentStoredAt = null;
        const metricsDimension = convertKeyToMetricsDimension(cacheKey);

        // Fetch the data
        const fetchPromise = fetchValue().then(result => {
          currentStoredAt = Date.now();

          // Overwrite any currently-persisted data with the result (but don't wait on it)
          setInIDB({
            key: toNamespacedKey(namespace, entityName, cacheKey),
            value: {
              data: result,
              storedAt: currentStoredAt
            },
            resolvePortalAndUserId
          }).catch(() => {
            // Errors are ignored, because we don't really care if this persists.
            // It's an optimization, the rest of the load will function just fine without it.
          });
          return result;
        });

        // Kick off a read from IndexedDB for the data. Rejects if the data isn't present, or was stale.
        // Will automatically consume a quickLoaded request if one is present for this key.
        const indexedDBReadPromise = withQuickLoad({
          namespace,
          entityName,
          cacheKey,
          baseLoad: () => getFromIDB({
            key: toNamespacedKey(namespace, entityName, cacheKey),
            resolvePortalAndUserId
          })
        }).then(({
          storedAt,
          data
        }) => {
          // Ensures we only return cached data for a certain amount of time, to prevent things
          // from getting permanently stuck
          if (storedAt !== currentStoredAt && storedAt + stalenessCutoff > Date.now()) {
            // Fire metrics collection code. Swallow all errors and don't await this — it
            // is for metrics only and is fine if it crashes out.
            if (enablePatchDiffing || additionalProcessing) {
              fetchPromise.then(fetchResult => runWorker({
                namespace,
                entityName,
                cacheKey,
                current: fetchResult,
                previous: data,
                enablePatchDiffing,
                normalizeForPatchDiffing,
                extraProcessing: additionalProcessing === null || additionalProcessing === void 0 ? void 0 : additionalProcessing.process
              })).then(({
                totalSizeBytes,
                patchSizeBytes,
                patchSizePct,
                extraProcessingResult
              }) => {
                // If additional processing has been requested, pass the output
                // of the processing to the handler.
                if (additionalProcessing) {
                  try {
                    additionalProcessing.handleResult({
                      segment: metricsDimension,
                      extraProcessingResult
                    });
                  } catch (e) {
                    // Do nothing. If the requested additional processing errors out, we'd still like
                    // to track the standardaized metrics below.
                  }
                }
                if (enablePatchDiffing) {
                  // This is the standardized "did this data change since the prior request" metric
                  // It relies on indexeddb reads/writes, so it is async.
                  return trackDataChanges({
                    namespace,
                    entityName,
                    cacheKey,
                    segment: metricsDimension,
                    totalSizeBytes,
                    patchSizeBytes,
                    patchSizePct,
                    resolvePortalAndUserId
                  });
                }
              }).catch(err => {
                if (!isDeployed) {
                  console.error(err);
                }
              });
            }
            return data;
          }

          // Throwing here makes error handling in the combined promises smoother.
          throw Error('Result was stale');
        });

        // If either promise rejects, we want to fall back to the other. To prevent circular dependency
        // issues, we orchestrate this by "enhancing" each promise with the expected error handling behavior
        // as separate calls from the initial setup.
        // If the fetch promise rejects, fall back on the indexeddb promise.
        const enhancedFetchPromise = fetchPromise.catch(fetchError => indexedDBReadPromise.then(result => {
          Metrics.counter('fault-tolerant-cache-used', Object.assign({
            namespace,
            entityName
          }, metricsDimension && {
            segment: metricsDimension
          })).increment();
          return result;
        }).catch(() => {
          // If the indexeddb promise rejects, surface the load error instead. This presents
          // an actionable/useful error to the caller, rather than surfacing "random issue with indexeddb".
          // This is a terminal state — both promises have rejected, so we cannot satisfy the request.
          throw fetchError;
        }));
        const enhancedIndexedDBReadPromise = indexedDBReadPromise
        // If IndexedDB wins the race, we need to do an additional check to ensure that
        // this cache is willing to tolerate some staleness.
        .then(result => {
          if ((topLevelEagerConfig || opts !== null && opts !== void 0 && opts.allowEagerCacheReturn) && currentStoredAt === null // We haven't stored the fetch result yet, which means it hasn't finished
          ) {
            Metrics.counter('eager-cache-used', Object.assign({
              namespace,
              entityName
            }, metricsDimension && {
              segment: metricsDimension
            })).increment();
            return result;
          }
          return fetchPromise;
        }).catch(
        // If the indexeddb promise rejects, fall back on the fetch promise. If the fetch promise rejects,
        // we have reached a terminal state — both promises have rejected, so we cannot satisfy the request.
        () => fetchPromise);

        // Race for either "enhanced" promise to complete. Both have the correct error handling built in, so we
        // don't care which settles first — we simply want to settle when either settles.
        return Promise.race([enhancedFetchPromise, enhancedIndexedDBReadPromise]).then(result => deepFreeze ? freeze(result) : result);
      }
    })
  });
}