import PropTypes from 'prop-types';
import styled from 'styled-components';
import { KOALA } from 'HubStyleTokens/colors';
import { rgba } from '../core/Color';
const isVertical = side => side === 'top' || side === 'bottom';
const UIOverhang = styled.div.withConfig({
  displayName: "UIOverhang",
  componentId: "u7e5a4-0"
})(["background-image:", ";background-size:", ";height:", ";width:", ";opacity:", ";pointer-events:none;position:absolute;top:", ";bottom:", ";left:", ";right:", ";"], ({
  gradientColor,
  side
}) => `linear-gradient(to ${side}, ${rgba(gradientColor, 0)}, ${gradientColor})`, ({
  side,
  size
}) => isVertical(side) ? `100% ${size}px}` : `${size}px 100%`, ({
  side,
  size
}) => isVertical(side) && `${size}px`, ({
  side,
  size
}) => !isVertical(side) && `${size}px`, ({
  opacity
}) => opacity, ({
  side
}) => side !== 'bottom' && 0, ({
  side
}) => side !== 'top' && 0, ({
  side
}) => side !== 'right' && 0, ({
  side
}) => side !== 'left' && 0);
UIOverhang.propTypes = {
  gradientColor: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  side: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  size: PropTypes.number.isRequired
};
UIOverhang.defaultProps = {
  gradientColor: KOALA,
  opacity: 1,
  size: 14
};
export default UIOverhang;