import produce from 'immer';
// @ts-expect-error not yet migrated
import * as actionTypes from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_SETTINGS_SLUG_RAW, UPDATE_CONTENT_MODEL_DOMAIN, FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable, { fromJS } from 'immutable';
// @ts-expect-error not yet migrated
import { getBooleanOrNull } from 'ContentEditorUI/utils/helpers';
import { isTemporarySlug, buildSlugWithLang, buildLanguageString } from 'ContentEditorUI/utils/contentModelURLUtils';
import withBaseContentModel, { withBaseContentModelAsJSObject } from 'ContentEditorUI/redux/reducers/contentModel/withBaseContentModel';
import featuredImageReducer, { featuredImageReducerAsJSObject } from 'ContentEditorUI/redux/reducers/contentModel/featuredImageReducer';
import pagePublishReducer, { pagePublishReducerAsJSObject } from 'page-editor-ui/redux/reducers/pagePublishReducer';
// @ts-expect-error not yet migrated
import { parseCrmObjectDynamicPageDataSourceIdSimpleName } from '../../utils/dynamicPagesUtils';
// @ts-expect-error not yet migrated
import { CRM_SOURCE_TYPE } from '../../constants/DynamicPagesDataSource';
const initialState = {
  contentTypeCategoryId: 0,
  css: {},
  cssText: '',
  dynamicPageDataSourceId: null,
  dynamicPageDataSourceType: null,
  dynamicPageHubDbTableId: null,
  enableDomainStylesheets: false,
  enableLayoutStylesheets: false,
  footerHtml: null,
  headHtml: null,
  includeDefaultCustomCss: false,
  pageStylesheets: [],
  password: null,
  propertyForDynamicPageCanonicalUrl: null,
  propertyForDynamicPageFeaturedImage: null,
  propertyForDynamicPageMetaDescription: null,
  propertyForDynamicPageTitle: null,
  propertyForDynamicPageSlug: null,
  publicAccessRules: [],
  publicAccessRulesEnabled: false,
  requirePassword: false,
  slug: '',
  subcategory: '',
  scheduledUpdateDate: null,
  translatedFromId: null
};
export const pageContentModelReducerAsJSObject = withBaseContentModelAsJSObject(pagePublishReducerAsJSObject, featuredImageReducerAsJSObject, (state = initialState, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case actionTypes.ADD_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      if (draft.pageStylesheets) draft.pageStylesheets.push(payload);
      return draft;
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        return Object.assign(draft, {
          contentTypeCategoryId: content.contentTypeCategoryId,
          css: content.css,
          cssText: content.cssText,
          dynamicPageDataSourceId: content.dynamicPageDataSourceType === CRM_SOURCE_TYPE ? parseCrmObjectDynamicPageDataSourceIdSimpleName(content.dynamicPageDataSourceId) : content.dynamicPageDataSourceId,
          dynamicPageDataSourceType: content.dynamicPageDataSourceType,
          enableDomainStylesheets: content.enableDomainStylesheets,
          enableLayoutStylesheets: content.enableLayoutStylesheets,
          footerHtml: content.footerHtml,
          headHtml: content.headHtml,
          includeDefaultCustomCss: content.includeDefaultCustomCss,
          pageStylesheets: content.attachedStylesheets,
          password: content.password,
          propertyForDynamicPageCanonicalUrl: content.propertyForDynamicPageCanonicalUrl,
          propertyForDynamicPageFeaturedImage: content.propertyForDynamicPageFeaturedImage,
          propertyForDynamicPageMetaDescription: content.propertyForDynamicPageMetaDescription,
          propertyForDynamicPageTitle: content.propertyForDynamicPageTitle,
          propertyForDynamicPageSlug: content.propertyForDynamicPageSlug,
          publicAccessRules: content.publicAccessRules,
          publicAccessRulesEnabled: content.publicAccessRulesEnabled,
          requirePassword: content.password !== null,
          slug: content.htmlTitle && isTemporarySlug(content.slug) ? '' : content.slug,
          translatedFromId: content.translatedFromId,
          subcategory: content.subcategory,
          scheduledUpdateDate: content.scheduledUpdateDate // TODO: temporarily, we get this value from the buffer later to be added in `context.hard_content_info` so that we can get this value from hard saved content model which will be more accurate.
        });
      }
    case FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED:
      draft.scheduledUpdateDate = payload.scheduledUpdateDate;
      return draft;
    // TODO: temporarily using hard save requests after publish to get this value stored here in the redux correctly. Same for blog posts.
    case actionTypes.REMOVE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      if (draft.pageStylesheets) {
        draft.pageStylesheets = draft.pageStylesheets.filter(stylesheet => stylesheet.id !== payload);
      }
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DEFAULT_CSS_STYLESHEETS:
      draft.includeDefaultCustomCss = getBooleanOrNull(payload);
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DOMAIN_STYLESHEETS:
      draft.enableDomainStylesheets = getBooleanOrNull(payload);
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_LAYOUT_STYLESHEETS:
      draft.enableLayoutStylesheets = getBooleanOrNull(payload);
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_FOOTER_HTML:
      draft.footerHtml = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HEAD_HTML:
      draft.headHtml = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HUBDB_TABLE:
      draft.dynamicPageHubDbTableId = payload ? payload.id : null;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_TYPE:
      draft.dynamicPageDataSourceType = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_ID:
      draft.dynamicPageDataSourceId = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SLUG:
      draft.propertyForDynamicPageSlug = payload.toString();
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_TITLE:
      draft.propertyForDynamicPageTitle = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_META:
      draft.propertyForDynamicPageMetaDescription = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_URL:
      draft.propertyForDynamicPageCanonicalUrl = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_IMAGE:
      draft.propertyForDynamicPageFeaturedImage = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET_ORDER:
      draft.pageStylesheets = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_REQUIRE_PASSWORD:
      draft.requirePassword = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PASSWORD:
      draft.password = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES:
      draft.publicAccessRules = payload;
      return draft;
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES_ENABLED:
      draft.publicAccessRulesEnabled = payload;
      return draft;
    case UPDATE_SETTINGS_SLUG_RAW:
      {
        const {
          slugRaw,
          languageString = ''
        } = payload;
        draft.slug = buildSlugWithLang(slugRaw, languageString);
        return draft;
      }
    case UPDATE_CONTENT_MODEL_DOMAIN:
      {
        const {
          bodyOverrideByDomain,
          language,
          domain,
          slugRaw
        } = payload;
        const slug = buildSlugWithLang(slugRaw, buildLanguageString(bodyOverrideByDomain, language, domain));
        draft.slug = slug;
        return draft;
      }
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE:
      {
        const {
          language,
          slugRaw,
          bodyOverrideByDomain,
          domain
        } = payload;
        const slug = buildSlugWithLang(slugRaw || '', buildLanguageString(bodyOverrideByDomain, language, domain));
        return Object.assign(draft, {
          slug,
          language
        });
      }
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE_MASTER:
      draft.translatedFromId = payload.translatedFromId;
      return draft;
    default:
      return draft;
  }
}));
const pageContentModelReducer = (state = Immutable.Map(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case actionTypes.ADD_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      return state.set('pageStylesheets', state.get('pageStylesheets').push(fromJS(payload)));
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        // Set this separately so that state.merge doesn't recursively turn
        // publicAccessRules into an Immutable List
        state = state.set('publicAccessRules', content.publicAccessRules);
        return state.merge({
          contentTypeCategoryId: content.contentTypeCategoryId,
          css: content.css,
          cssText: content.cssText,
          dynamicPageDataSourceId: content.dynamicPageDataSourceType === CRM_SOURCE_TYPE ? parseCrmObjectDynamicPageDataSourceIdSimpleName(content.dynamicPageDataSourceId) : content.dynamicPageDataSourceId,
          dynamicPageDataSourceType: content.dynamicPageDataSourceType,
          enableDomainStylesheets: content.enableDomainStylesheets,
          enableLayoutStylesheets: content.enableLayoutStylesheets,
          footerHtml: content.footerHtml,
          headHtml: content.headHtml,
          includeDefaultCustomCss: content.includeDefaultCustomCss,
          pageStylesheets: content.attachedStylesheets,
          password: content.password,
          propertyForDynamicPageCanonicalUrl: content.propertyForDynamicPageCanonicalUrl,
          propertyForDynamicPageFeaturedImage: content.propertyForDynamicPageFeaturedImage,
          propertyForDynamicPageMetaDescription: content.propertyForDynamicPageMetaDescription,
          propertyForDynamicPageTitle: content.propertyForDynamicPageTitle,
          propertyForDynamicPageSlug: content.propertyForDynamicPageSlug,
          publicAccessRulesEnabled: content.publicAccessRulesEnabled,
          requirePassword: content.password !== null,
          slug: content.htmlTitle && isTemporarySlug(content.slug) ? '' : content.slug,
          translatedFromId: content.translatedFromId,
          subcategory: content.subcategory,
          scheduledUpdateDate: content.scheduledUpdateDate // TODO: temporarily, we get this value from the buffer later to be added in `context.hard_content_info` so that we can get this value from hard saved content model which will be more accurate.
        });
      }
    case FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED:
      return state.set('scheduledUpdateDate', payload.scheduledUpdateDate);
    // TODO: temporarily using hard save requests after publish to get this value stored here in the redux correctly. Same for blog posts.
    case actionTypes.REMOVE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      return state.set('pageStylesheets', state.get('pageStylesheets').filter(stylesheet => stylesheet.get('id') !== payload));
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DEFAULT_CSS_STYLESHEETS:
      return state.set('includeDefaultCustomCss', getBooleanOrNull(payload));
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DOMAIN_STYLESHEETS:
      return state.set('enableDomainStylesheets', getBooleanOrNull(payload));
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_LAYOUT_STYLESHEETS:
      return state.set('enableLayoutStylesheets', getBooleanOrNull(payload));
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_FOOTER_HTML:
      return state.set('footerHtml', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HEAD_HTML:
      return state.set('headHtml', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HUBDB_TABLE:
      return state.set('dynamicPageHubDbTableId', payload ? payload.id : null);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_TYPE:
      return state.set('dynamicPageDataSourceType', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_ID:
      return state.set('dynamicPageDataSourceId', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SLUG:
      return state.set('propertyForDynamicPageSlug', payload.toString());
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_TITLE:
      return state.set('propertyForDynamicPageTitle', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_META:
      return state.set('propertyForDynamicPageMetaDescription', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_URL:
      return state.set('propertyForDynamicPageCanonicalUrl', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_IMAGE:
      return state.set('propertyForDynamicPageFeaturedImage', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET_ORDER:
      return state.set('pageStylesheets', fromJS(payload));
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_REQUIRE_PASSWORD:
      return state.set('requirePassword', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PASSWORD:
      return state.set('password', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES:
      return state.set('publicAccessRules', payload);
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES_ENABLED:
      return state.set('publicAccessRulesEnabled', payload);
    case UPDATE_SETTINGS_SLUG_RAW:
      {
        const {
          slugRaw,
          languageString = ''
        } = payload;
        return state.set('slug', buildSlugWithLang(slugRaw, languageString));
      }
    case UPDATE_CONTENT_MODEL_DOMAIN:
      {
        const {
          bodyOverrideByDomain,
          language,
          domain,
          slugRaw
        } = payload;
        const slug = buildSlugWithLang(slugRaw, buildLanguageString(bodyOverrideByDomain, language, domain));
        return state.set('slug', slug);
      }
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE:
      {
        const {
          language,
          slugRaw,
          bodyOverrideByDomain,
          domain
        } = payload;
        const slug = buildSlugWithLang(slugRaw || '', buildLanguageString(bodyOverrideByDomain, language, domain));
        return state.merge({
          slug,
          language
        });
      }
    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE_MASTER:
      return state.set('translatedFromId', payload.translatedFromId);
    default:
      return state;
  }
};
export default withBaseContentModel(pagePublishReducer, featuredImageReducer, pageContentModelReducer);