'use es6';

import { pick, memoize } from 'underscore';
import { createSelector } from 'reselect';
import { getModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getContentWidgets, getWidgetsInRichText } from 'ContentEditorUI/redux/selectors/moduleSelectorHelpers';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { isCmv2GlobalModule } from 'ContentEditorUI/data/moduleUtils';
import { hasImmutableOrPlain, getImmutableOrPlain } from 'ContentEditorUI/utils/dataHelpers';

// Allowlisted fields are copied from CMUI
// https://git.hubteam.com/HubSpot/ContentModulesUI/blob/master/static/coffee/models/widget.coffee#L85
const ALLOWLISTED_WIDGET_FIELDS = ['body', 'css', 'child_css', 'id', 'meta', 'name', 'type', 'order', 'label', 'definition_id', 'smart_type', 'smart_objects', 'version', 'styles', 'module_id', 'merge_in_default_values'];
const ALLOWLISTED_GLOBAL_V1_FIELDS = ['name', 'id', 'order', 'type', 'global_widget_name'];
const ALLOWLISTED_GLOBAL_V2_FIELDS = ['id', 'name', 'type', 'order', 'version', 'module_id'];
const isV1Global = module => getImmutableOrPlain(module, 'type') === 'global_widget';
export const isGlobal = (module, moduleSchema) => isV1Global(module) || isCmv2GlobalModule(module, moduleSchema);
const getAllowedGlobalCmv2Fields = isInRichText => isInRichText ? [...ALLOWLISTED_GLOBAL_V2_FIELDS, 'body'] : ALLOWLISTED_GLOBAL_V2_FIELDS;
const serializeV1Global = module => Object.assign({}, pick(module.toJS(), ALLOWLISTED_GLOBAL_V1_FIELDS), {
  body: {
    // CMv1 globals in a flex column coming from a template
    // do not have `global_widget_name` value, but instead a `name` value.
    // After this initial save with the `name` value, it will be stored
    // as `global_widget_name` going forward, essentially migrating the "bad" data.
    global_widget_name: module.getIn(['body', 'global_widget_name']) || module.getIn(['body', 'name'])
  }
});
export const serializeWidget = (module, moduleSchema, {
  isInRichText
} = {}) => {
  if (isV1Global(module)) {
    return serializeV1Global(module);
  } else if (isCmv2GlobalModule(module, moduleSchema)) {
    return pick(module.toJS(), getAllowedGlobalCmv2Fields(isInRichText));
  } else {
    return pick(module.toJS(), ALLOWLISTED_WIDGET_FIELDS);
  }
};
const serializeWidgetsInRichText = (widgets, getSchema) => {
  const widgetsInRichText = {};
  if (!widgets) {
    return widgetsInRichText;
  }
  Object.entries(widgets).forEach(([name, widget]) => {
    widgetsInRichText[name] = serializeWidget(widget, getSchema(widget), {
      isInRichText: true
    });
  });
  return widgetsInRichText;
};

// TODO, another good candiate where multi-level selector memoization will make a significant impact
// (e.g. only toJS and serialize the modules that have changed, not _all_ modules anytime one is changed)
export const makeGetModuleSaveData = (modulesSelector, moduleSchemasSelector = getAllModuleSchemasAsArray, moduleIdsByBuiltinTypeSelector = getModuleIdsByBuiltinType, contentWidgetSelector = getContentWidgets, widgetsInRichTextSelector = getWidgetsInRichText, categoryIdSelector = getCategoryId) => createSelector([modulesSelector, moduleSchemasSelector, moduleIdsByBuiltinTypeSelector, contentWidgetSelector, widgetsInRichTextSelector, categoryIdSelector], (modules, moduleSchemas, moduleIdsByBuiltinType, contentWidgets, widgetsInRichText, categoryId) => {
  const widgets = {};
  const widget_containers = {};

  // Memoize the call to lookup the schema per module so we don't look it up multiple times
  const getSchema = memoize(module => {
    const r = getSchemaForModuleHelper(module, moduleSchemas, moduleIdsByBuiltinType, categoryId);
    return r;
  });
  Object.entries(modules).forEach(([name, module]) => {
    if (module.get('type') === 'container') {
      const containerWidgets = module.get('widgets').map(w => {
        w = w.set('id', w.get('key')); // To match backbone
        return serializeWidget(w, getSchema(w));
      });
      widget_containers[name] = {
        widgets: containerWidgets
      };
    } else {
      if (module.get('type') !== 'fake' && module.get('type') !== 'layout_section' && !module.get('isInContainer') && !module.get('layout_section_id') && !isGlobal(module, getSchema(module))) {
        // Only save the content for widgets which have changed, not all widgets.
        if (hasImmutableOrPlain(contentWidgets, name) ||
        // Explicitly check for preview_text since it's a special case legacy module, which gets omitted from getContentWidgets.
        name === 'preview_text') {
          widgets[name] = serializeWidget(module, getSchema(module));
        }
      }
    }
  });
  const widgets_in_rich_text = serializeWidgetsInRichText(widgetsInRichText, getSchema);
  return {
    widgets,
    widget_containers,
    widgets_in_rich_text
  };
});
export const getModuleSaveData = makeGetModuleSaveData(getModules);