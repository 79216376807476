import { SET_MODULE_ERRORS, CONTENT_SAVE_REQUEST_SUCCEEDED, FETCH_MODULE_ERRORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
// @ts-expect-error not yet migrated
import { convertObjectToCamelCase } from 'ContentEditorUI/utils/helpers';
import produce from 'immer';
const INITIAL_STATE = [];
const moduleErrorReducer = (state = INITIAL_STATE, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case SET_MODULE_ERRORS:
      return response.errors.map(convertObjectToCamelCase);
    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          config: {
            setModuleErrorsAfterSave
          },
          response: {
            data: {
              errors = []
            }
          }
        } = payload;
        if (setModuleErrorsAfterSave) {
          return errors.map(convertObjectToCamelCase);
        }
        return draft;
      }
    case FETCH_MODULE_ERRORS_SUCCEEDED:
      return response;
    default:
      return draft;
  }
});
export default moduleErrorReducer;