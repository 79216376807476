'use es6';

import { FEATURE_FLAGS } from 'ContentEditorUI/redux/constants';
const throwHelper = (key, path) => {
  let nicePath = 'editorConfigRoot';
  path.forEach(step => {
    nicePath = `${nicePath}>${step}`;
  });
  console.error(`Editor config missing ${key} in path: ${nicePath}`);
  throw Error(`Editor config missing ${key} in path: ${nicePath}`);
};
const validateKeys = (path, requiredKeys, editorConfig, valueCheckerFn = null) => {
  let objToCheck = editorConfig;
  // Walk path to find spot in editor config to check
  path.forEach(key => {
    if (key) {
      objToCheck = objToCheck[key];
    }
  });
  requiredKeys.forEach(key => {
    // If a custom checker is passed in, invoke that. Otherwise just check
    // to make sure the value is not undefined
    if (valueCheckerFn) {
      valueCheckerFn(key, objToCheck[key]);
    } else if (objToCheck[key] === undefined) {
      throwHelper(key, path);
    }
  });
};
const validateUserAttributes = editorConfig => {
  const {
    api
  } = editorConfig;
  if (api.hasOwnProperty('userAttributesToFetch')) {
    const {
      userAttributesToFetch
    } = api;
    if (!Array.isArray(userAttributesToFetch)) {
      console.error('If using optional config value userAttributesToFetch on the api key, it must be of type array');
      throw new Error('If using optional config value userAttributesToFetch on the api key, it must be of type array');
    }
  }
};
const validateRootUrl = url => {
  if (!url.includes('{portalId}')) {
    console.error('The root url in infra>rootUrl should contain the string `{portalId}`');
    throw new Error('The root url in infra>rootUrl should contain the string `{portalId}`');
  }
};
const validateReduxMakeInitialState = editorConfig => {
  const isDefined = editorConfig.redux.makeInitialState !== undefined;
  const isValidCallback = typeof editorConfig.redux.makeInitialState === 'function';
  if (isDefined && !isValidCallback) {
    console.error('If using optional config value `makeInitialState` on the `redux` key, it must be of type function');
    throw new Error('If using optional config value `makeInitialState` on the `redux` key, it must be of type function');
  }
};

// We should only list _required_ keys for the app to function. This just ensures they are included but doesn't validate if thing being passed in is something that will actually work.
export const validateEditorConfig = editorConfig => {
  // Validate top level keys
  validateKeys([], ['redux', 'components', 'routes', 'infra', 'api'], editorConfig);

  //Validate redux keys
  validateKeys(['redux'], ['reducers', 'selectors', 'actions'], editorConfig);
  validateKeys(['redux', 'reducers'], ['rootReducer'], editorConfig);
  validateKeys(['redux', 'selectors'], ['getSaveData', 'getCanSave', 'getHasPermissionsToLoadEditor'], editorConfig);
  validateKeys(['redux', 'actions'], ['autoSave', 'autoSaveDebounced', 'save'], editorConfig);
  validateReduxMakeInitialState(editorConfig);

  // Validate components
  validateKeys(['components'], ['asyncComponents', 'HeaderExtension', 'TabBarExtension', 'SidebarExtension', 'RootTreeWrapper', 'ExtraApplicationContainerComponents'], editorConfig);

  // TODO: Should we validate routes?

  // Validate infra
  validateKeys(['infra'], ['rootUrl', 'rhumbConfig', 'lang'], editorConfig);
  validateRootUrl(editorConfig.infra.rootUrl);

  // Validate api
  validateKeys(['api'], ['API_BASE_URL'], editorConfig);

  // Validate user attributes is of type array if present
  validateUserAttributes(editorConfig);

  // Validate feature flags
  validateKeys(['features'], Object.values(FEATURE_FLAGS), editorConfig, (key, value) => {
    const isBoolean = typeof value === 'boolean';
    const isObject = typeof value === 'object';
    let isNonEmptyObject = false;
    if (isObject) {
      isNonEmptyObject = Object.keys(value).length > 0;
    }
    // This sort of check is perfect for TypeScript if we ever get around to it.
    const isValidFeatureKey = isBoolean || isObject && isNonEmptyObject;
    if (!isValidFeatureKey) {
      console.error(`Feature flag ${key} must be either a boolean or a non empty object`);
      throw new Error(`Feature flag ${key} must be either a boolean or a non empty object`);
    }
  });
};