import http from 'hub-http/clients/apiClient';
import { gate as hubHttpGate } from 'hub-http/gates';
import { userInfoSync } from 'hub-http/userInfo';
import { useQuery, registerQuery } from 'data-fetching-client';
import { SENTRY_KEYS } from 'campaigns-lib/constants/sentry';
import Raven from 'raven-js';
const CAMPAIGNS_APP_SETTING_NAMESPACE = 'campaignsAssociations';
const GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME = 'campaignsPlatformComponentFrameworkAppSettings';
const GET_FRAMEWORK_APP_SETTINGS = registerQuery({
  fieldName: GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME,
  args: [],
  fetcher() {
    return http.post(`/framework-builder/v1/read/metadata/type/all/batch`, {
      data: [CAMPAIGNS_APP_SETTING_NAMESPACE]
    });
  }
});

// Fetches the framework-app-settings for the all defined campaigns associations.
export function useFetchCampaignsAssociationSettings(skip = false) {
  const {
    data,
    loading
  } = useQuery(GET_FRAMEWORK_APP_SETTINGS, {
    onError: error => Raven.captureException(error, {
      fingerprint: [SENTRY_KEYS.ASSOCIATED_CAMPAIGN_FETCH_FAILED],
      tags: {
        lib: 'campaigns-lib'
      }
    }),
    skip
  });
  return {
    data: data === null || data === void 0 ? void 0 : data[GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME],
    loading
  };
}
export function hasCampaignsReadAccess(associationSettings, objectType) {
  const {
    requiredGates
  } = associationSettings[objectType].campaignsAssociations.metadata;
  const {
    gates,
    user: {
      scopes
    }
  } = userInfoSync();
  const hasGates = requiredGates !== null && requiredGates !== void 0 && requiredGates.value ? requiredGates.value.every(gate =>
  // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
  gates.includes(gate) || gates.includes(hubHttpGate(gate))) : true;
  return hasGates && scopes.includes('campaigns-access');
}
export function hasCampaignsWriteAccess(associationSettings, objectType) {
  const {
    requiredGates
  } = associationSettings[objectType].campaignsAssociations.metadata;
  const {
    gates,
    user: {
      scopes
    }
  } = userInfoSync();
  const hasGates = requiredGates !== null && requiredGates !== void 0 && requiredGates.value ? requiredGates.value.every(gate =>
  // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
  gates.includes(gate) || gates.includes(hubHttpGate(gate))) : true;
  return hasGates && scopes.includes('campaigns-write');
}
export function useObjectTypeHasCampaignsAccess(objectType) {
  const {
    data: associationSettings,
    loading
  } = useFetchCampaignsAssociationSettings();
  return Boolean(!loading && associationSettings && associationSettings[objectType] && hasCampaignsReadAccess(associationSettings, objectType));
}