export const ACTION_TYPE_PREFIX = '@@tinymce-plugins/';
const getActionType = type => `${ACTION_TYPE_PREFIX}${type}`;
export const CLOSE_MODAL_COMPLETE = getActionType('CLOSE_MODAL_COMPLETE');
export const OPEN_LINK_MODAL = getActionType('OPEN_LINK_MODAL');
export const CLOSE_LINK_MODAL = getActionType('CLOSE_LINK_MODAL');
export const CLOSE_LINK_MODAL_COMPLETE = getActionType('CLOSE_LINK_MODAL_COMPLETE');
export const SAVE_LINK = getActionType('SAVE_LINK');
export const OPEN_CODE_EDITOR_MODAL = getActionType('OPEN_CODE_EDITOR_MODAL');
export const CLOSE_CODE_EDITOR_MODAL = getActionType('CLOSE_CODE_EDITOR_MODAL');
export const SAVE_SOURCE_CODE = getActionType('SAVE_SOURCE_CODE');
export const OPEN_FILE_MANAGER_MODAL = getActionType('OPEN_FILE_MANAGER_MODAL');
export const CLOSE_FILE_MANAGER_MODAL = getActionType('CLOSE_FILE_MANAGER_MODAL');
export const CLOSE_FILE_MANAGER_MODAL_COMPLETE = getActionType('CLOSE_FILE_MANAGER_MODAL_COMPLETE');
export const OPEN_EDIT_VIDEO_2_MODAL = getActionType('OPEN_EDIT_VIDEO_2_MODAL');
export const CLOSE_EDIT_VIDEO_MODAL = getActionType('CLOSE_EDIT_VIDEO_MODAL');
export const SAVE_EDIT_VIDEO_MODAL = getActionType('SAVE_EDIT_VIDEO_MODAL');
export const OPEN_VIDEO_PICKER = getActionType('OPEN_VIDEO_PICKER');
export const CLOSE_VIDEO_PICKER = getActionType('CLOSE_VIDEO_PICKER');
export const INSERT_VIDEO = getActionType('INSERT_VIDEO');
export const OPEN_DISALLOWED_HTML_WARNING = getActionType('OPEN_DISALLOWED_HTML_WARNING');
export const SHOW_FLOATING_ALERT = getActionType('SHOW_FLOATING_ALERT');
export const CLOSE_ANY_MODAL = getActionType('CLOSE_ANY_MODAL');
export const OPEN_VIDEO_CONVERSION_ASSET_OPTIONS = getActionType('OPEN_VIDEO_CONVERSION_ASSET_OPTIONS');
export const CLOSE_VIDEO_CONVERSION_ASSET_OPTIONS = getActionType('CLOSE_VIDEO_CONVERSION_ASSET_OPTIONS');
export const SAVE_VIDEO_CONVERSION_ASSET_OPTIONS = getActionType('SAVE_VIDEO_CONVERSION_ASSET_OPTIONS');
export const INSERT_IMAGE = getActionType('INSERT_IMAGE');
export const INSERT_FILE = getActionType('INSERT_FILE');
export const OPEN_CTA_PICKER = getActionType('OPEN_CTA_PICKER');
export const CLOSE_CTA_PICKER = getActionType('CLOSE_CTA_PICKER');
export const INSERT_CTA = getActionType('INSERT_CTA');
export const CLOSE_FORM_PICKER = getActionType('CLOSE_FORM_PICKER');
export const MEDIA_MODAL_OPEN = getActionType('MEDIA_MODAL_OPEN');
export const MEDIA_MODAL_CLOSE = getActionType('MEDIA_MODAL_CLOSE');
export const MEDIA_MODAL_SUBMIT = getActionType('MEDIA_MODAL_SUBMIT');