import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
// @ts-expect-error not yet migrated
import { exportStaticSection } from 'layout-data-lib/LayoutDataTree/serialize';
// @ts-expect-error not yet migrated
import * as SectionsApi from 'ContentEditorUI/api/SectionsApi';
import { getAllLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
// @ts-expect-error not yet migrated
import { getAppKey } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import { getRenderLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { optimisticallyAddCssAsset } from 'ContentEditorUI/redux/actions/contentAssetsActions';
import Raven from 'raven-js';
const formatSectionHtmlRequestPayload = ({
  appKey,
  language,
  section
}) => {
  const data = exportStaticSection(section);
  const query = {
    hsEditorApp: appKey
  };
  data.language = language;
  return {
    data,
    query
  };
};
const handleSectionHtmlResponse = (bus, response, sectionVersion) => dispatch => {
  const {
    assets,
    errors,
    html,
    name,
    warnings
  } = response;
  const hasWarnings = warnings && warnings.length > 0;
  const hasRenderErrors = errors && errors.length > 0;
  const {
    css,
    cssToAdd,
    jsFooter,
    jsHead
  } = assets;
  const sectionHasCss = Boolean(css.length || cssToAdd.length);
  const sectionHasJs = Boolean(jsHead.length || jsFooter.length);

  // Currently we don't do anything with warnings for module render requests.
  // Maybe we should?
  if (hasWarnings) {
    const warningMessage = `Section render warnings for ${name}:\n - ${errors.map(error => error.message || error.errorType).join('\n - ')}`;
    console.warn(warningMessage);
    Raven.captureBreadcrumb({
      level: 'warning',
      message: warningMessage
    });
  }
  if (hasRenderErrors) {
    const errorMessage = `Section render errors for ${name}:\n - ${errors.map(error => error.message || error.errorType).join('\n - ')}`;
    console.error(errorMessage);
    Raven.captureBreadcrumb({
      level: 'error',
      message: errorMessage
    });
    FloatingAlertStore.addAlert({
      message: I18n.text('addSectionPanel.error.alert.message'),
      titleText: I18n.text('addSectionPanel.error.modal.title'),
      type: 'warning',
      timeout: 10000,
      id: `section-render-error-${name}`
    });
  }

  // If the section has js or render errors, save and refresh the preview
  // instead of live updating
  if (sectionHasJs || hasRenderErrors) {
    bus.trigger('saveAndTriggerRefresh');
  } else {
    if (sectionHasCss) {
      dispatch(optimisticallyAddCssAsset([...css, ...cssToAdd]));
    }
    const messagePayload = {
      action: 'htmlReceivedForSection',
      moduleHtml: html,
      moduleName: name,
      moduleVersion: sectionVersion
    };
    bus.postMessage(messagePayload);
  }
};
export const fetchSectionWithMeta = (bus, sectionName, sectionVersion) => async (dispatch, getState) => {
  const state = getState();
  const allLayoutSections = getAllLayoutSections(state);
  const appKey = getAppKey(state);
  const language = getRenderLanguage(state);
  const section = allLayoutSections.find(ls => ls.getName() === sectionName);
  if (!section) {
    // If we're calling this from handling a fetch section html request from
    // inpage, then this should never happen.
    throw new Error(`Error fetching section with meta. No section found with name ${sectionName}.`);
  }
  const requestPayload = formatSectionHtmlRequestPayload({
    appKey,
    language,
    section
  });
  const response = await SectionsApi.renderSectionWithMeta(requestPayload);
  dispatch(handleSectionHtmlResponse(bus, response, sectionVersion));
};