import { Component } from 'react';
import Raven from 'raven-js';
export default class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  componentDidCatch(error, errorInfo) {
    if (this.props.sentryKey) {
      Raven.captureException(error, {
        fingerprint: [this.props.sentryKey],
        extra: errorInfo,
        tags: {
          lib: 'campaigns-lib'
        }
      });
    }
  }
  render() {
    const {
      hasError
    } = this.state;
    const {
      children,
      renderFallback
    } = this.props;
    return hasError ? renderFallback() : children;
  }
}