module.exports = {
  "/:pageId": {
    "name": "legacyRoute1"
  },
  "/:pageId/content": {
    "success": {
      "main editor loaded": [
        "PARENT_APP_CHROME_RENDERED",
        "INPAGE_EDITOR_READY",
        "ADD_MODULE_SIDEBAR_READY"
      ],
      "main editor loaded no add module sidebar": [
        "PARENT_APP_CHROME_RENDERED",
        "INPAGE_EDITOR_READY",
        "ADD_MODULE_SIDEBAR_NOT_NEEDED"
      ],
      "main editor read only mode": [
        "PARENT_APP_CHROME_RENDERED",
        "READ_ONLY_MODE"
      ]
    },
    "error": [
      "ASYNC_COMPONENT_FAILED_TO_LOAD",
      "ERROR_PAGE_RENDERED"
    ]
  },
  "/:pageId/optimize": {
    "success": {
      "optimize tab loaded": [
        "PARENT_APP_CHROME_RENDERED",
        "INPAGE_EDITOR_READY",
        "OPTIMIZE_TAB_RENDERED"
      ]
    },
    "error": [
      "ASYNC_COMPONENT_FAILED_TO_LOAD",
      "ERROR_PAGE_RENDERED"
    ]
  },
  "/:pageId/settings": {
    "success": {
      "settings tab loaded": [
        "PARENT_APP_CHROME_RENDERED",
        "SETTINGS_TAB_RENDERED"
      ]
    },
    "error": [
      "ASYNC_COMPONENT_FAILED_TO_LOAD",
      "ERROR_PAGE_RENDERED"
    ]
  },
  "/:pageId/preview": {
    "success": {
      "preview modal loaded": [
        "PARENT_APP_CHROME_RENDERED",
        "PREVIEW_MODAL_RENDERED"
      ]
    },
    "error": [
      "ASYNC_COMPONENT_FAILED_TO_LOAD",
      "ERROR_PAGE_RENDERED"
    ]
  },
  "/:pageId/revisions": {
    "success": {
      "revisions modal loaded": [
        "PARENT_APP_CHROME_RENDERED",
        "REVISIONS_MODAL_RENDERED"
      ]
    },
    "error": [
      "ASYNC_COMPONENT_FAILED_TO_LOAD",
      "ERROR_PAGE_RENDERED"
    ]
  }
};