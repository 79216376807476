/**
 * @note Keep up to date with ./ProductNames until that file is deprecated
 * https://git.hubteam.com/HubSpot/growth-trials-meta/issues/38
 */
export const HUBSPOT_FREE = 'hubspot-free';
export const SALES_HUB_FREE = 'sales-hub-free';
export const SALES_HUB_STARTER = 'sales-hub-starter';
export const SALES_HUB_PROFESSIONAL = 'sales-hub-professional';
export const SALES_HUB_PROFESSIONAL_ONBOARDING = 'sales-hub-professional-onboarding';
export const SALES_HUB_ENTERPRISE = 'sales-hub-enterprise';
export const SALES_HUB_ENTERPRISE_ONBOARDING = 'sales-hub-enterprise-onboarding';

// Only use this for legacy stuff
export const MARKETING_HUB_BASIC = 'marketing-hub-basic';
export const MARKETING_HUB_FREE = 'marketing-hub-free';
export const MARKETING_HUB_STARTER = 'marketing-hub-starter';
export const MARKETING_HUB_PROFESSIONAL = 'marketing-hub-professional';
export const MARKETING_HUB_PROFESSIONAL_ONBOARDING = 'marketing-hub-professional-onboarding';
export const MARKETING_HUB_ENTERPRISE = 'marketing-hub-enterprise';
export const MARKETING_HUB_ENTERPRISE_ONBOARDING = 'marketing-hub-enterprise-onboarding';
export const SERVICE_HUB_FREE = 'service-hub-free';
export const SERVICE_HUB_STARTER = 'service-hub-starter';
export const SERVICE_HUB_PROFESSIONAL = 'service-hub-professional';
export const SERVICE_HUB_PROFESSIONAL_ONBOARDING = 'service-hub-professional-onboarding';
export const SERVICE_HUB_ENTERPRISE = 'service-hub-enterprise';
export const SERVICE_HUB_ENTERPRISE_ONBOARDING = 'service-hub-enterprise-onboarding';
export const CMS_HUB_FREE = 'cms-hub-free';
export const CMS_HUB_STARTER = 'cms-hub-starter';
export const CMS_HUB_PROFESSIONAL = 'cms-hub-professional';
export const CMS_HUB_ENTERPRISE = 'cms-hub-enterprise';
export const OPERATIONS_HUB_FREE = 'operations-hub-free';
export const OPERATIONS_HUB_STARTER = 'operations-hub-starter';
export const OPERATIONS_HUB_PROFESSIONAL = 'operations-hub-professional';
export const OPERATIONS_HUB_ENTERPRISE = 'operations-hub-enterprise';
export const DEDICATED_IP = 'tech-service-dedicated-ip-for-email';
export const CUSTOM_SSL = 'web-hosting-custom-ssl-pack';
export const REPORTING_LIMIT_INCREASE = 'reporting-limit-increase';
export const TRANSACTIONAL_EMAIL = 'transactional-email';
export const BRAND_DOMAIN = 'brand-domain';
export const MARKETING_SMS_ADDON = 'marketing-sms';
export const MARKETING_SMS_SHORT_CODE = 'marketing-sms-short-code';
export const API_LIMIT_INCREASE = 'api-limit-increase';
export const ADS_LIMIT_INCREASE = 'ads-limit-increase';
export const TEAMS_LIMIT_INCREASE = 'teams-limit-increase';
export const TRANSCRIPTION_LIMIT_INCREASE = 'transcription-limit-increase';
export const LISTS_LIMIT_INCREASE = 'lists-limit-increase';
export const DOMAINS_LIMIT_INCREASE = 'domains-limit-increase';
export const SANDBOX_LIMIT_INCREASE = 'sandbox-limit-increase';
export const CALLING_LIMIT_INCREASE = 'calling-limit-increase';
export const PHONE_LIMIT_INCREASE = 'phone-limit-increase';
export const ESIGNATURES_LIMIT_INCREASE = 'esignatures-limit-increase';
export const ESIGNATURE_LIMIT_INCREASE = 'esignature-limit-increase';
export const WHATSAPP_LIMIT_INCREASE = 'whatsapp-limit-increase';
export const VIDEO_LIMIT_INCREASE = 'video-limit-increase';
export const MARKETING_SMS_LIMIT_INCREASE = 'marketing-sms-limit-increase';
export const WORKFLOWS_LIMIT_INCREASE = 'workflows-limit-increase';
export const USERS_LIMIT_INCREASE = 'users-limit-increase';
export const CUSTOM_OBJECTS_LIMIT_INCREASE = 'custom-objects-limit-increase';
export const CALCULATED_PROPERTIES_LIMIT_INCREASE = 'calculated-properties-limit-increase';
export const CUSTOM_PROPERTIES_LIMIT_INCREASE = 'custom-properties-limit-increase';
export const CRM_OBJECT_LIMIT_INCREASE = 'crm-object-limit-increase';
export const CRM_CONTACT_RECORDS_LIMIT_INCREASE = 'non-marketing-contacts-limit-increase';
export const STRATEGIC_CONSULTING_MONTHLY = 'strategic-consulting-monthly';
export const STRATEGIC_CONSULTING_ONGOING = 'strategic-consulting-ongoing';
export const STRATEGIC_CONSULTING_4_HOUR = 'strategic-consulting-4-hour';
export const INBOUND_CONSULTING_ADVANCED = 'inbound-consulting-advanced';
export const INBOUND_CONSULTING_PREMIUM = 'inbound-consulting-premium';
export const TECHNICAL_CONSULTING_ONGOING = 'technical-consulting-ongoing';
export const TECHNICAL_CONSULTING_4_HOUR = 'technical-consulting-4-hour';
export const TECHNICAL_CONSULTING_ADVANCED = 'technical-consulting-advanced';
export const TECHNICAL_CONSULTING_PREMIUM = 'technical-consulting-premium';
export const PREMIUM_CONSULTING = 'premium-consulting';
export const ONBOARDING_FOUNDATIONS = 'onboarding-foundations';
export const TRAINING_AGENCY_MARKETING_HUB_FUNDAMENTALS = 'training-agency-marketing-hub-fundamentals';
export const MARKETING_HUB_STARTER_ONBOARDING = 'marketing-hub-starter-onboarding';
export const SALES_HUB_STARTER_ONBOARDING = 'sales-hub-starter-onboarding';
export const SERVICE_HUB_STARTER_ONBOARDING = 'service-hub-starter-onboarding';
export const CMS_HUB_PROFESSIONAL_ONBOARDING = 'cms-hub-professional-onboarding';
export const CMS_HUB_ENTERPRISE_ONBOARDING = 'cms-hub-enterprise-onboarding';
export const HUBSPOT_FOR_STARTUPS_SETUP = 'hubspot-for-startups-setup';
export const BRAZIL_ONBOARDING = 'brazil-onboarding';
export const FIRST_FULL_TIME_MIGRATION = 'first-time-full-migration';
export const STARTER_KIT = 'starter-kit';
export const CRM_SUITE_STARTER_BUNDLE = 'crm-suite-starter-bundle';
export const CRM_SUITE_PROFESSIONAL_BUNDLE = 'crm-suite-professional-bundle';
export const CRM_SUITE_ENTERPRISE_BUNDLE = 'crm-suite-enterprise-bundle';
export const CORE_SEATS_INCLUDED_STARTER = 'core-seats-included-starter';
export const CORE_SEATS_STARTER = 'core-seats-starter';
export const CORE_SEATS_PROFESSIONAL = 'core-seats-professional';
export const CORE_SEATS_ENTERPRISE = 'core-seats-enterprise';
export const MARKETING_PLUS_PROFESSIONAL_BUNDLE = 'marketing-plus-professional-bundle';
export const MARKETING_PLUS_ENTERPRISE_BUNDLE = 'marketing-plus-enterprise-bundle';
export const BREEZE_INTELLIGENCE_CREDITS_100 = 'breeze-intelligence-credits-100';
export const BREEZE_INTELLIGENCE_CREDITS_1000 = 'breeze-intelligence-credits-1000';
export const BREEZE_INTELLIGENCE_CREDITS_10000 = 'breeze-intelligence-credits-10000';
export const BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED = 'breeze-intelligence-credits-100-committed';
export const BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED = 'breeze-intelligence-credits-1000-committed';
export const BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED = 'breeze-intelligence-credits-10000-committed';
export const BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_100 = 'breeze-intelligence-overage-credits-for-100';
export const BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_1000 = 'breeze-intelligence-overage-credits-for-1000';
export const BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_10000 = 'breeze-intelligence-overage-credits-for-10000';