import http from 'hub-http/clients/apiClient';
import Categories from 'ContentUtils/constants/Categories';
import I18n from 'I18n';
import { getContentTypeId } from 'ContentEditorUI/utils/contentManagementPanel/contentTableUtils';
const getUri = contentTypeId => {
  switch (contentTypeId) {
    case Categories.blog_listing_page.id:
      return 'blogs/v3/blog-listing-pages';
    case Categories.blog_post.id:
      return 'blogs/v3/blog-posts';
    case Categories.landing_page.id:
      return 'cospages/v2/landing-pages';
    case Categories.site_page.id:
      return 'cospages/v2/site-pages';
    default:
      return '';
  }
};
function generateUrl(contentType, id) {
  return `${getUri(contentType)}/${id}`;
}
export const cloneContent = (content, name) => {
  const {
    contentGroupId,
    id
  } = content;
  const contentTypeId = getContentTypeId(content);
  const sharedData = {
    name,
    state: 'DRAFT',
    publishDate: new Date().getTime()
  };
  const contentTypeSpecificData = contentTypeId === Categories.blog_post.id ? {
    contentGroupId
  } : {
    variantOnly: false
  };
  return http.post(`${generateUrl(contentTypeId, id)}/clone`, {
    data: Object.assign({}, sharedData, contentTypeSpecificData)
  });
};
export const deleteContent = content => {
  const contentTypeId = getContentTypeId(content);
  return http.delete(generateUrl(contentTypeId, content.id));
};
export const createBlogPost = (blogId, language) => {
  return http.post('blogs/v3/blog-posts', {
    data: {
      contentGroupId: blogId,
      name: I18n.text('contentManagementPanel.table.defaultPostTitle'),
      language,
      publishDate: new Date().getTime()
    }
  });
};
export const createTranslationVariation = (content, language, masterLanguage) => {
  const {
    id,
    name,
    contentGroupId
  } = content;
  const contentTypeId = getContentTypeId(content);
  const data = {
    language,
    name
  };
  if (contentTypeId === Categories.blog_post.id) {
    data.contentGroupId = contentGroupId;
  } else {
    data.masterLanguage = masterLanguage;
  }
  return http.post(`${generateUrl(contentTypeId, id)}/create-language-variation`, {
    data
  });
};
export const createBlogListingPage = (blogId, name) => {
  return http.post(getUri(Categories.blog_listing_page.id), {
    data: {
      contentGroupId: blogId,
      name
    }
  });
};
export const translateContent = ({
  content,
  name,
  language,
  masterLanguage,
  shouldCloneContent
}) => {
  const {
    id,
    contentGroupId
  } = content;
  const contentTypeId = getContentTypeId(content);
  const data = {
    language,
    name,
    autoCreateContentGroupLanguageVariation: true
  };
  if (contentTypeId === Categories.blog_post.id) {
    data.contentGroupId = contentGroupId;
  } else {
    data.masterLanguage = masterLanguage;
  }
  return http.post(`${generateUrl(contentTypeId, id)}/translate/in-place`, {
    data,
    timeout: 20000,
    query: shouldCloneContent ? {
      cloneFirst: true
    } : {}
  });
};