import { defaultXrayEditorApi } from 'ContentEditorUI/lib/xray/defaultEditorXrayApi';
// @ts-expect-error file is untyped JS
import { openFormModule } from 'ContentEditorUI/redux/actions/recommenderActions';
// @ts-expect-error not in TS yet
import { mergeModuleBody } from 'ContentEditorUI/redux/actions/moduleActions';
import { getSeoSectionConfigForPages } from 'xray-recommendation-lib/seo/configs/seoSectionConfig';
import memoizeOne from 'react-utils/memoizeOne';
export const xrayEditorApi = Object.assign({}, defaultXrayEditorApi, {
  mergeModuleBody,
  openFormModule
});
export const getXrayConfig = memoizeOne(auth => getSeoSectionConfigForPages(xrayEditorApi, auth));