import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
const GET_OWNER_FIELD_NAME = 'owners';
const GET_OWNER = registerQuery({
  fieldName: GET_OWNER_FIELD_NAME,
  args: ['ownerId'],
  fetcher({
    ownerId
  }) {
    return http.get(`/owners/v2/owners/batch-v2`, {
      query: {
        ownerId
      }
    }).then(response => response[ownerId]);
  }
});
export function useFetchOwner({
  ownerId
}) {
  const {
    data,
    loading
  } = useQuery(GET_OWNER, {
    variables: {
      ownerId
    }
  });
  return {
    data: data === null || data === void 0 ? void 0 : data[GET_OWNER_FIELD_NAME],
    loading
  };
}