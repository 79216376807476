import http from 'hub-http/clients/apiClient';
import { USER_OBJECT_TYPE_ID, HS_INTERNAL_USER_ID } from '../constants/CrmProperties';
const API_BASE = 'crm/v3/objects';
export default class CrmObjectApi {
  static getObjectByInternalUserId(userId, propertyNames) {
    return http.post(`${API_BASE}/${USER_OBJECT_TYPE_ID}/batch/read`, {
      data: {
        idProperty: HS_INTERNAL_USER_ID,
        inputs: [{
          id: userId.toString()
        }],
        properties: propertyNames
      }
    });
  }
  static updateObjectProperties(userId, properties) {
    return http.patch(`${API_BASE}/${USER_OBJECT_TYPE_ID}/${userId.toString()}`, {
      data: {
        properties
      },
      query: {
        idProperty: HS_INTERNAL_USER_ID
      }
    });
  }
}