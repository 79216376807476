'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { getIsPublished } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getIsLandingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContentModelPageExpiryDate, getContentModelPageExpiryEnabled, getPublishDate, getShouldPublishImmediately } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCurrentDomainIsNotPermitted, getDomainExclusionReason } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getIsAllowedToPublishWithHubdb } from 'page-editor-ui/redux/selectors/pageSettingsSelectors';
import { getHasExceededMlangPagesLimit } from 'page-editor-ui/redux/selectors/pageLimitSelectors';
import * as SimpleDate from 'UIComponents/core/SimpleDate';
import { getHasLandingPagesPublishScope, getHasSitePagesPublishScope, getIsUngatedForPostPublishContentRemixModal } from 'page-editor-ui/redux/selectors/authSelectors';
import { getCannotPublishOnDomainReason } from 'ContentEditorUI/utils/validationUtils';
import { getActiveDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import cannotPublishOnDomainReasons from 'ContentEditorUI/constants/cannotPublishOnDomainReasons';
import { getIsPublishEnabledBase } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getHardSavedContentModelRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestStarted, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { getHardSavedContentModelIsScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
import { getHardSavedContentModelIsPublishedNotScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
import { getHasAccountTrustScope, getHasPagesSchedulingUpdateAccess, getHasBlogListingPagesWriteScope, getHasContentManagementSavedViews, getHasContentRemixWriteAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getAccountVerificationStatus } from 'ContentEditorUI/redux/selectors/accountVerificationStatusSelectors';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getDomainExclusionReasonRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';

// Checks that hard-saved page (not the buffer) is in the scheduled state
export const getIsHardSavedContentScheduledToPublish = createSelector([getHardSavedContentModelRequest, getHardSavedContentModelIsScheduled], (hardSavedContentModelRequest, hardSavedContentModelIsScheduled) => {
  if (!requestStarted(hardSavedContentModelRequest)) {
    return !!hardSavedContentModelIsScheduled;
  }
  return false;
});
export const getIsLive = createSelector([getIsPublished, getIsHardSavedContentScheduledToPublish], (isPublished, isPageScheduled) => {
  return isPublished && !isPageScheduled;
});
export const getMaxPublishDateFromExpiryDate = createSelector([getContentModelPageExpiryDate, getContentModelPageExpiryEnabled], (pageExpiryDate, pageExpiryEnabled) => {
  if (pageExpiryEnabled && pageExpiryDate) {
    return SimpleDate.fromMoment(I18n.moment(pageExpiryDate));
  }
  return null;
});
export const getHasPermissionsToPublish = createSelector([getIsSitePage, getHasLandingPagesPublishScope, getHasSitePagesPublishScope, getCurrentDomainIsNotPermitted, getHasAccountTrustScope, getHasBlogListingPagesWriteScope, getIsBlogListingPage], (isSitePage, hasPermissionsToPublishLandingPage, hasPermissionsToPublishSitePage, currentDomainIsNotPermitted, hasAccountTrust, hasBlogListingPagesWriteScope, isBlogListingPage) => {
  let userPermission;
  if (isSitePage) {
    userPermission = hasPermissionsToPublishSitePage;
  } else if (isBlogListingPage) {
    userPermission = hasBlogListingPagesWriteScope;
  } else {
    userPermission = hasPermissionsToPublishLandingPage;
  }
  return userPermission && hasAccountTrust && !currentDomainIsNotPermitted;
});
export const getIsSchedulingAlreadyPublished = createSelector([getShouldPublishImmediately, getHardSavedContentModelIsPublishedNotScheduled, getHasPagesSchedulingUpdateAccess], (publishImmediately, hardSavedContentModelIsPublishedNotScheduled, hasPagesSchedulingUpdateAccess) => !publishImmediately && hardSavedContentModelIsPublishedNotScheduled && !hasPagesSchedulingUpdateAccess);
export const getPublishValidationErrors = createSelector([getPublishDate, getShouldPublishImmediately, getHasAccountTrustScope, getAccountVerificationStatus], (publishDate, publishImmediately, hasAccountTrust, accountVerificationStatus) => {
  const validationErrors = {};
  if (!publishImmediately && !publishDate) {
    validationErrors.publishDate = {
      pane: 'publishing',
      type: 'required',
      messageKey: 'publish.scheduleRequiredDateError',
      target: 'publishDate'
    };
  }
  if (!hasAccountTrust) {
    validationErrors.hasLowAccountTrust = {
      pane: 'publishing',
      type: 'accountVerificationSuspended',
      messageKey: `accountVerification.${accountVerificationStatus}.actionTooltip`
    };
  }
  return validationErrors;
});
export const getIsPublishEnabled = createSelector([getIsPublishEnabledBase, getHasPermissionsToPublish, getIsAllowedToPublishWithHubdb, getHasExceededMlangPagesLimit], (isPublishEnabledBase, hasPermissionsToPublish, isAllowedToPublishWithHubdb, hasExceededMlangPagesLimit) => {
  return Boolean(isPublishEnabledBase && hasPermissionsToPublish && isAllowedToPublishWithHubdb && !hasExceededMlangPagesLimit);
});
export const getIsUnpublishEnabled = createSelector([getIsPublished, getHasPermissionsToPublish, getIsBlogListingPage], (isPublished, hasPermissionsToPublish, isBlogListingPage) => isPublished && hasPermissionsToPublish && !isBlogListingPage);
export const getCannotPublishOnActiveDomainReason = createSelector([getHasPermissionsToPublish, getActiveDomain, getIsSitePage, getHasLandingPagesPublishScope, getHasSitePagesPublishScope, getHasAccountTrustScope, getCurrentDomainIsNotPermitted, getDomainExclusionReason], (hasPermissionsToPublish, activeDomain, isSitePage, hasLandingPagesPublishScope, hasSitePagesPublishScope, hasAccountTrust, domainIsNotPermitted, domainExclusionReason) => {
  if (hasPermissionsToPublish || !isSitePage && !hasAccountTrust) {
    return '';
  }
  const contentTypeStr = isSitePage ? 'SitePage' : 'LandingPage';
  const userHasPublishAccess = isSitePage ? hasSitePagesPublishScope : hasLandingPagesPublishScope;
  return getCannotPublishOnDomainReason({
    domain: activeDomain,
    domainIsNotPermitted,
    domainExclusionReason,
    contentTypeStr,
    userHasPublishAccess
  });
});
export const getCannotPublishReasonShouldAllowSwitchDomain = createSelector([getCannotPublishOnActiveDomainReason, getDomainExclusionReasonRequest, getCurrentDomainIsNotPermitted], (cannotPublishOnDomainReason, domainExclusionReasonRequest, currentDomainIsNotPermitted) => !currentDomainIsNotPermitted || requestSucceeded(domainExclusionReasonRequest) && (!cannotPublishOnDomainReason || cannotPublishOnDomainReason !== cannotPublishOnDomainReasons.USER_HAS_NO_PUBLISH_ACCESS && cannotPublishOnDomainReason !== cannotPublishOnDomainReasons.NO_DOMAIN_PERMISSION));
export const getIsSchedulingUpdate = createSelector([getShouldPublishImmediately, getHardSavedContentModelIsPublishedNotScheduled, getHasPagesSchedulingUpdateAccess], (publishImmediately, hardSavedContentModelIsPublishedNotScheduled, hasPagesSchedulingUpdateAccess) => hasPagesSchedulingUpdateAccess && !publishImmediately && hardSavedContentModelIsPublishedNotScheduled);
export const getPagesTypeHasSavedViews = createSelector([getHasContentManagementSavedViews, getIsLandingPage, getIsSitePage], (hasContentManagementSavedViews, isLandingPage, isSitePage) => {
  return hasContentManagementSavedViews && (isLandingPage || isSitePage);
});
export const getIsEligibleForPostPublishContentRemixModal = createSelector([getHasContentRemixWriteAccess, getIsUngatedForPostPublishContentRemixModal], (hasContentRemixWriteAccess, isUngatedForPostPublishContentRemixModal) => {
  return hasContentRemixWriteAccess && isUngatedForPostPublishContentRemixModal;
});