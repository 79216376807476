import UserTimezonesApi from '../api/UserTimezonesApi';
import { autoUpdateTimezone } from './autoUpdateTimezone';
import { gate } from 'hub-http/gates';
export const HS_STANDARD_TIME_ZONE = 'hs_standard_time_zone';
function getCachedUserObjectTimezone() {
  let value;
  try {
    var _storedValue$time;
    const storedValueStr = localStorage.getItem(HS_STANDARD_TIME_ZONE) || '{}';
    const storedValue = JSON.parse(storedValueStr);
    const TEN_MINUTES = 1000 * 60 * 10;
    const isStale = Date.now() - ((_storedValue$time = storedValue.time) !== null && _storedValue$time !== void 0 ? _storedValue$time : 0) > TEN_MINUTES;
    if (!isStale) {
      value = storedValue.value;
    }
  } catch (e) {
    console.error('Error getting user object timezone', e);
  }
  return value;
}
export function setCachedUserObjectTimezone(value) {
  const cachedValue = {
    value,
    time: Date.now()
  };
  try {
    localStorage.setItem(HS_STANDARD_TIME_ZONE, JSON.stringify(cachedValue));
  } catch (e) {
    console.error('Error setting user object timezone', e);
  }
}

/**
 * Used in conjunction with `I18nProvider` and `userInfo` to
 * set up the User Object Timezone (`I18n.moment.userObjectTz()`)
 *
 * @example
 * ```tsx
 * const I18nProvider = createStandardI18nProvider();
 * const { user, portal, userObjectTimezone } = await userInfo().then(hydrateUserInfo);
 * I18nProvider.setLocale({
 *   locale: user.locale,
 *   langEnabled: user.lang_enabled,
 *   timezone: portal?.timezone,
 *   userObjectTimezone,
 * });
 * ```
 */
export function hydrateUserInfo(info) {
  const {
    gates,
    user
  } = info;
  // If gated, do nothing, return original info
  if (!gates.includes(gate('UserSetup:TimezoneUtil'))) {
    return Promise.resolve(info);
  }

  // If user has no timezone_id, do nothing, return original info
  // @ts-expect-error UserInfo types out of date
  const userTimezoneId = getCachedUserObjectTimezone() || user.timezone_id;
  if (!userTimezoneId) {
    return Promise.resolve(info);
  }
  if (gates.includes(gate('UserSetup:TimezoneAutoUpdate'))) {
    // Don't wait for this to finish, it's unnecessary for page load
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    autoUpdateTimezone(user.user_id, userTimezoneId);
  }
  return UserTimezonesApi.moment(userTimezoneId).then(({
    data
  }) => {
    const momentData = data;
    return Object.assign({}, info, {
      userObjectTimezone: {
        id: userTimezoneId,
        'moment-data': momentData
      }
    });
  }).catch(e => {
    console.error(e);
    return info;
  });
}