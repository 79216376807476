const AudienceAccessTypes = Object.freeze({
  ALL: 'all',
  PASSWORD: 'password',
  SPECIFIC_CONTACTS: 'specificContacts',
  SSO: 'sso',
  SSO_WITH_SPECIFIC_CONTACTS: 'ssoWithSpecificContacts',
  ACCESS_GROUP_MEMBERSHIP: 'accessGroupMembership',
  SSO_WITH_ACCESS_GROUPS: 'ssoWithAccessGroups'
});
/**
 * @deprecated - use PublicAccessRuleTypes and utils from ui-memberships-settings-lib
 */
export default AudienceAccessTypes;