import { getFromIDB, setInIDB } from '../persistence/indexeddb';
import { Metrics } from '../metrics';
import { toNamespacedKey } from '../utils/toNamespacedKey';
export const trackDataChanges = ({
  namespace,
  entityName,
  segment,
  cacheKey,
  resolvePortalAndUserId,
  totalSizeBytes,
  patchSizeBytes,
  patchSizePct
}) => {
  const key = toNamespacedKey(namespace, entityName, cacheKey);
  return getFromIDB({
    key,
    store: 'metrics',
    resolvePortalAndUserId
  }).catch(() => 0).then(result => {
    if (patchSizeBytes === 0) {
      // Increment the identical request counter by one
      return setInIDB({
        key,
        value: result + 1,
        store: 'metrics',
        resolvePortalAndUserId
      });
    } else {
      // Report the count of identical requests that we reached
      Metrics.histogram('duplicate-request-count', Object.assign({
        namespace,
        entityName
      }, segment ? {
        segment
      } : {})).update(result);

      // Report the size of the response (after normalization, in bytes)
      Metrics.histogram('total-size', Object.assign({
        namespace,
        entityName
      }, segment ? {
        segment
      } : {})).update(totalSizeBytes);

      // Report the size of the hypothetical json-patch operations (in bytes)
      Metrics.histogram('patch-size', Object.assign({
        namespace,
        entityName
      }, segment ? {
        segment
      } : {})).update(patchSizeBytes);

      // Report the percentage size of the patch as compared to the normalized data
      // (i.e how efficient would this patch have been)
      Metrics.histogram('patch-pct', Object.assign({
        namespace,
        entityName
      }, segment ? {
        segment
      } : {})).update(patchSizePct);

      // Reset our count to 0
      return setInIDB({
        key,
        value: 0,
        store: 'metrics',
        resolvePortalAndUserId
      });
    }
  });
};