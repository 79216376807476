import { createSelector } from 'reselect';
import { getContentModelHtmlTitle as baseGetContentModelHtmlTitle } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
// @ts-expect-error not yet migrated
import { getContentGroup } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getAbMasterId, getIsABVariant } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { PublicAccessRuleTypes } from 'ui-memberships-settings-lib/constants/PublicAccessRuleTypes';
import { basicSelector, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsUngatedForImmerContentModel } from 'ContentEditorUI/redux/selectors/authSelectors';
const getImmutablePageContentModel = basicSelector(state => state.contentModel);
const getImmerPageContentModel = basicSelector(state => state.contentModel);
export const getPageContentModel = makeGatedSelector(getIsUngatedForImmerContentModel, getImmutablePageContentModel, getImmerPageContentModel);
const createPageContentModelSelector = field => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getPageContentModel.gated], contentModel => {
  return contentModel.get(field);
}), createSelector([getPageContentModel.ungated], contentModel => {
  return contentModel[field];
}));
export const getContentModelCss = createPageContentModelSelector('css');
const getContentModelCssImmutableToJSObject = createSelector([getContentModelCss.gated], css => css.toJS());
export const getContentModelCssAsJSObject = makeGatedSelector(getIsUngatedForImmerContentModel, getContentModelCssImmutableToJSObject, getContentModelCss);
export const getContentModelContentTypeCategoryId = createPageContentModelSelector('contentTypeCategoryId');
export const getContentModelDefaultCustomCssPath = createPageContentModelSelector('defaultCustomCssPath');
export const getContentModelEnableDomainStylesheets = createPageContentModelSelector('enableDomainStylesheets');
export const getContentModelEnableLayoutStylesheets = createPageContentModelSelector('enableLayoutStylesheets');
export const getContentModelFooterHtml = createPageContentModelSelector('footerHtml');
export const getContentScheduledUpdateDate = createPageContentModelSelector('scheduledUpdateDate');
export const getContentModelHeadHtml = createPageContentModelSelector('headHtml');
export const getContentModelIncludeDefaultCustomCss = createPageContentModelSelector('includeDefaultCustomCss');
export const getContentModelPageStylesheets = createPageContentModelSelector('pageStylesheets');
const getContentModelPageStylesheetsImmutableToJSObject = createSelector([getContentModelPageStylesheets.gated], pageStylesheets => pageStylesheets.toJS());
export const getContentModelPageStylesheetsAsJSObject = makeGatedSelector(getIsUngatedForImmerContentModel, getContentModelPageStylesheetsImmutableToJSObject, getContentModelPageStylesheets.ungated);
export const getContentModelPassword = createPageContentModelSelector('password');
export const getContentModelRequirePassword = createPageContentModelSelector('requirePassword');
const _getContentModelPublicAccessRules = createPageContentModelSelector('publicAccessRules');

// Temporary workaround to cast publicAccessRules to be a POJO, since otherwise
// RecursiveImmutable turns this into an ImmutableList when gated
export const getContentModelPublicAccessRules = createSelector([_getContentModelPublicAccessRules], publicAccessRules => publicAccessRules);
export const getContentModelPublicAccessRulesEnabled = createPageContentModelSelector('publicAccessRulesEnabled');
export const getIsPublicContent = createSelector([getContentModelPassword, getContentModelPublicAccessRulesEnabled], (password, publicAccessRulesEnabled) => {
  return !password && !publicAccessRulesEnabled;
});
export const getContentModelContentIsPublic = createSelector([getContentModelRequirePassword, getContentModelPassword, getContentModelPublicAccessRules], (contentModelRequirePassword, contentModelPassword, publicAccessRules) => {
  const emptyPublicAccessRules = !publicAccessRules || publicAccessRules.length === 0;
  if (emptyPublicAccessRules) {
    return !(contentModelRequirePassword && contentModelPassword);
  }
  return publicAccessRules[0].type === PublicAccessRuleTypes.PUBLIC;
});
export const getPageSubCategory = createPageContentModelSelector('subcategory');
export const getDynamicPageSourceType = createPageContentModelSelector('dynamicPageDataSourceType');
export const getContentModelDynamicPageSourceValue = createPageContentModelSelector('dynamicPageDataSourceId');
export const getContentModelDynamicPageUrl = createPageContentModelSelector('propertyForDynamicPageCanonicalUrl');
export const getContentModelDynamicPageImage = createPageContentModelSelector('propertyForDynamicPageFeaturedImage');
export const getContentModelDynamicPageMeta = createPageContentModelSelector('propertyForDynamicPageMetaDescription');
export const getContentModelDynamicPageTitle = createPageContentModelSelector('propertyForDynamicPageTitle');
export const getContentModelDynamicPageSlug = createPageContentModelSelector('propertyForDynamicPageSlug');
export const getContentModelHtmlTitle = createSelector([baseGetContentModelHtmlTitle, getIsBlogListingPage, getContentGroup], (contentModelHtmlTitle, isBlogListingPage, contentGroup) => {
  if (isBlogListingPage) {
    return contentGroup.htmlTitle;
  }
  return contentModelHtmlTitle;
});
export const getIsDynamicPage = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue], (dynamicPageSourceType, dynamicPageSourceValue) => !!dynamicPageSourceType && !!dynamicPageSourceValue);
export const getIsAudienceAccessPage = createSelector([getContentModelPublicAccessRulesEnabled, getContentModelPublicAccessRules], (pagePublicAccessRulesEnabled, pagePublicAccessRules) => pagePublicAccessRulesEnabled || pagePublicAccessRules && pagePublicAccessRules.length > 0);
export const getIsPrivatePage = createSelector([getContentModelPassword, getIsAudienceAccessPage], (pagePassword, isAudienceAccessPage) => pagePassword || isAudienceAccessPage);
export const getApprovalContentId = createSelector([getIsABVariant, getAbMasterId, getContentModelId], (isABVariant, abMasterId, contentModelId) => isABVariant ? abMasterId : contentModelId);