'use es6';

import { createSelector } from 'reselect';
import { getIsSitePage, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { createHasScopeSelector, getHasBlogListingPagesWriteScope, getIsUngatedForImmerContentModel } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getHasAudienceAccessScope, getHasHidePrimaryCss } from 'page-editor-ui/redux/selectors/authSelectors';
import { getPagePrimaryDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getIsSaveInProgress, getIsSavePermanentlyDisabled } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getContentModel } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentModelPageStylesheetsAsJSObject, getContentModelPassword, getContentModelPublicAccessRules, getContentModelPublicAccessRulesEnabled } from 'page-editor-ui/redux/selectors/pageContentModelSelectors';
import { getLayoutSectionsAsJson } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getModuleSaveData } from 'ContentEditorUI/redux/selectors/moduleSaveSelectors';
import { getThemePageOverrides } from 'ContentEditorUI/redux/selectors/themeSelectorsAdvanced';
import { isTemporarySlug } from 'ContentEditorUI/utils/contentModelURLUtils';
import { basicSelectorWithStats, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsReadOnlyMode } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { getIsDynamicPageSourceValuePortalSpecificCrmObject } from './pageSettingsSelectors';
import { formatCrmObjectDynamicPageDataSourceIdForSave } from '../../utils/dynamicPagesUtils';
import { getContentModelCssAsJSObject } from './pageContentModelSelectors';
export const getHasPermissionsToSaveLandingPages = createHasScopeSelector('landingpages-write');
export const getHasPermissionsToSaveSitePages = createHasScopeSelector('pages-write');
export const getHasPermissionsToSave = createSelector([getIsSitePage, getHasPermissionsToSaveLandingPages, getHasPermissionsToSaveSitePages, getHasBlogListingPagesWriteScope, getIsBlogListingPage], (isSitePage, hasPermissionsToSaveLandingPages, hasPermissionsToSaveSitePages, hasBlogListingPagesWriteScope, isBlogListingPage) => {
  if (isSitePage) {
    return hasPermissionsToSaveSitePages;
  } else if (isBlogListingPage) {
    return hasBlogListingPagesWriteScope;
  }
  return hasPermissionsToSaveLandingPages;
});
export const getIsSavingEnabled = createSelector([getHasPermissionsToSave, getIsSaveInProgress, getIsSavePermanentlyDisabled], (hasPermissionsToSave, isSaveInProgress, isSavePermanentlyDisabled) => hasPermissionsToSave && !isSaveInProgress && !isSavePermanentlyDisabled);

// Fields to subscribe to backbone's change listener
const getImmutableModelDataForBackbone = createSelector([getContentModel, getContentModelPassword, getLayoutSectionsAsJson, getHasAudienceAccessScope, getContentModelPublicAccessRules, getContentModelPublicAccessRulesEnabled, getHasHidePrimaryCss, getThemePageOverrides, getIsDynamicPageSourceValuePortalSpecificCrmObject, getContentModelPageStylesheetsAsJSObject, getContentModelCssAsJSObject], (contentModel, contentModelPassword, layoutSectionsAsJson, hasAudienceAccessWrite, contentModelPublicAccessRules, contentModelPublicAccessRulesEnabled, hasHidePrimaryCss, themePageOverrides, isDynamicPageSourceValuePortalSpecificCrmObject, attachedStylesheetsAsJSObject, cssAsJSObject) => {
  const result = {
    attached_stylesheets: attachedStylesheetsAsJSObject,
    campaign: contentModel.get('campaign'),
    campaign_name: contentModel.get('campaignName'),
    campaign_utm: contentModel.get('campaignUtm'),
    css: cssAsJSObject || {},
    css_text: contentModel.get('cssText'),
    domain: contentModel.get('domain'),
    dynamic_page_hub_db_table_id: contentModel.get('dynamicPageHubDbTableId'),
    dynamic_page_data_source_id: formatCrmObjectDynamicPageDataSourceIdForSave(contentModel.get('dynamicPageDataSourceId'), isDynamicPageSourceValuePortalSpecificCrmObject),
    dynamic_page_data_source_type: contentModel.get('dynamicPageDataSourceType'),
    property_for_dynamic_page_slug: contentModel.get('propertyForDynamicPageSlug'),
    property_for_dynamic_page_title: contentModel.get('propertyForDynamicPageTitle'),
    property_for_dynamic_page_meta_description: contentModel.get('propertyForDynamicPageMetaDescription'),
    property_for_dynamic_page_canonical_url: contentModel.get('propertyForDynamicPageCanonicalUrl'),
    property_for_dynamic_page_featured_image: contentModel.get('propertyForDynamicPageFeaturedImage'),
    enable_domain_stylesheets: contentModel.get('enableDomainStylesheets'),
    enable_layout_stylesheets: contentModel.get('enableLayoutStylesheets'),
    featured_image: contentModel.get('featuredImage'),
    featured_image_alt_text: contentModel.get('featuredImageAltText'),
    footer_html: contentModel.get('footerHtml'),
    head_html: contentModel.get('headHtml'),
    html_title: contentModel.get('htmlTitle') || null,
    include_default_custom_css: contentModel.get('includeDefaultCustomCss'),
    language: contentModel.get('language'),
    layout_sections: layoutSectionsAsJson,
    link_rel_canonical_url: contentModel.get('linkRelCanonicalUrl'),
    meta_description: contentModel.get('metaDescription') || null,
    name: contentModel.get('name'),
    page_expiry_date: contentModel.get('pageExpiryDate'),
    page_expiry_enabled: contentModel.get('pageExpiryEnabled'),
    page_expiry_redirect_id: contentModel.get('pageExpiryRedirectId'),
    page_expiry_redirect_url: contentModel.get('pageExpiryRedirectUrl'),
    publish_date: contentModel.get('publishDate'),
    publish_immediately: contentModel.get('publishImmediately'),
    slug: contentModel.get('slug') || '/',
    template_path: contentModel.get('templatePath'),
    theme_settings_values: themePageOverrides,
    translated_from_id: contentModel.get('translatedFromId'),
    use_featured_image: contentModel.get('useFeaturedImage')
  };
  if (contentModel.get('requirePassword') && !hasAudienceAccessWrite) {
    result.password = contentModelPassword || null;
  } else {
    result.password = null;
  }
  if (hasAudienceAccessWrite) {
    result.public_access_rules = contentModelPublicAccessRules;
    result.public_access_rules_enabled = contentModelPublicAccessRulesEnabled;
    result.password = contentModelPassword;
  }
  if (hasHidePrimaryCss && result.include_default_custom_css) {
    result.include_default_custom_css = false;
  }
  return result;
});
const getImmerModelDataForBackbone = createSelector([getContentModel, getContentModelPassword, getLayoutSectionsAsJson, getHasAudienceAccessScope, getContentModelPublicAccessRules, getContentModelPublicAccessRulesEnabled, getHasHidePrimaryCss, getThemePageOverrides, getIsDynamicPageSourceValuePortalSpecificCrmObject, getContentModelPageStylesheetsAsJSObject, getContentModelCssAsJSObject], (contentModel, contentModelPassword, layoutSectionsAsJson, hasAudienceAccessWrite, contentModelPublicAccessRules, contentModelPublicAccessRulesEnabled, hasHidePrimaryCss, themePageOverrides, isDynamicPageSourceValuePortalSpecificCrmObject, attachedStylesheetsAsJSObject, cssAsJSObject) => {
  const result = {
    attached_stylesheets: attachedStylesheetsAsJSObject,
    campaign: contentModel.campaign,
    campaign_name: contentModel.campaignName,
    campaign_utm: contentModel.campaignUtm,
    css: cssAsJSObject || {},
    css_text: contentModel.cssText,
    domain: contentModel.domain,
    dynamic_page_hub_db_table_id: contentModel.dynamicPageHubDbTableId,
    dynamic_page_data_source_id: formatCrmObjectDynamicPageDataSourceIdForSave(contentModel.dynamicPageDataSourceId, isDynamicPageSourceValuePortalSpecificCrmObject),
    dynamic_page_data_source_type: contentModel.dynamicPageDataSourceType,
    property_for_dynamic_page_slug: contentModel.propertyForDynamicPageSlug,
    property_for_dynamic_page_title: contentModel.propertyForDynamicPageTitle,
    property_for_dynamic_page_meta_description: contentModel.propertyForDynamicPageMetaDescription,
    property_for_dynamic_page_canonical_url: contentModel.propertyForDynamicPageCanonicalUrl,
    property_for_dynamic_page_featured_image: contentModel.propertyForDynamicPageFeaturedImage,
    enable_domain_stylesheets: contentModel.enableDomainStylesheets,
    enable_layout_stylesheets: contentModel.enableLayoutStylesheets,
    featured_image: contentModel.featuredImage,
    featured_image_alt_text: contentModel.featuredImageAltText,
    footer_html: contentModel.footerHtml,
    head_html: contentModel.headHtml,
    html_title: contentModel.htmlTitle || null,
    include_default_custom_css: contentModel.includeDefaultCustomCss,
    language: contentModel.language,
    layout_sections: layoutSectionsAsJson,
    link_rel_canonical_url: contentModel.linkRelCanonicalUrl,
    meta_description: contentModel.metaDescription || null,
    name: contentModel.name,
    page_expiry_date: contentModel.pageExpiryDate,
    page_expiry_enabled: contentModel.pageExpiryEnabled,
    page_expiry_redirect_id: contentModel.pageExpiryRedirectId,
    page_expiry_redirect_url: contentModel.pageExpiryRedirectUrl,
    publish_date: contentModel.publishDate,
    publish_immediately: contentModel.publishImmediately,
    slug: contentModel.slug || '/',
    template_path: contentModel.templatePath,
    theme_settings_values: themePageOverrides,
    translated_from_id: contentModel.translatedFromId,
    use_featured_image: contentModel.useFeaturedImage
  };
  if (contentModel.requirePassword && !hasAudienceAccessWrite) {
    result.password = contentModelPassword || null;
  } else {
    result.password = null;
  }
  if (hasAudienceAccessWrite) {
    result.public_access_rules = contentModelPublicAccessRules;
    result.public_access_rules_enabled = contentModelPublicAccessRulesEnabled;
    result.password = contentModelPassword;
  }
  if (hasHidePrimaryCss && result.include_default_custom_css) {
    result.include_default_custom_css = false;
  }
  return result;
});
const getModelDataForBackbone = makeGatedSelector(getIsUngatedForImmerContentModel, getImmutableModelDataForBackbone, getImmerModelDataForBackbone);

// Save data minus all widget data
const _getModelSaveData = createSelector([getModelDataForBackbone, getPagePrimaryDomain], (modelData, primaryDomain) => {
  if (modelData.domain === primaryDomain) {
    modelData.domain = '';
  }
  return modelData;
});
export const getModelSaveData = basicSelectorWithStats((state, opts = {}) => {
  const modelSaveData = _getModelSaveData(state);
  const {
    slug
  } = modelSaveData;
  if (opts.isSaveBeforePublish && isTemporarySlug(slug)) {
    modelSaveData.slug = slug.substring(0, slug.indexOf('-temporary-slug-'));
  }
  return modelSaveData;
});

// All save data
export const getSaveData = createSelector([getModelSaveData, getModuleSaveData], (modelSaveData, moduleSaveData) => Object.assign({}, modelSaveData, moduleSaveData));
export const getCanSave = createSelector([getHasPermissionsToSave, getIsSavePermanentlyDisabled, getIsReadOnlyMode], (hasPermissionsToSave, isSavePermanentlyDisabled, isReadOnly) => hasPermissionsToSave && !isSavePermanentlyDisabled && !isReadOnly);