import Categories from 'ContentUtils/constants/Categories';
import * as ModuleContentTypes from 'ContentUtils/constants/ModuleContentTypes';
const HostTemplateTypes = {
  PAGE: 'PAGE',
  EMAIL: 'EMAIL',
  BLOG_POST: 'BLOG_POST',
  BLOG_LISTING_PAGE: 'BLOG_LISTING'
};
export const CategoryToContentType = {
  [Categories.email.name]: ModuleContentTypes.EMAIL,
  [Categories.site_page.name]: ModuleContentTypes.SITE_PAGE,
  [Categories.landing_page.name]: ModuleContentTypes.LANDING_PAGE,
  [Categories.blog_post.name]: ModuleContentTypes.BLOG_POST,
  [Categories.blog_listing_page.name]: ModuleContentTypes.BLOG_LISTING,
  [Categories.case_study_instance_layout.name]: ModuleContentTypes.CASE_STUDY
};
export const CategoryToHostTemplateType = {
  [Categories.email.name]: HostTemplateTypes.EMAIL,
  [Categories.site_page.name]: HostTemplateTypes.PAGE,
  [Categories.landing_page.name]: HostTemplateTypes.PAGE,
  [Categories.blog_post.name]: HostTemplateTypes.BLOG_POST,
  [Categories.blog_listing_page.name]: HostTemplateTypes.BLOG_LISTING_PAGE,
  // Structured content types
  [Categories.kb_article_instance_layout.name]: HostTemplateTypes.PAGE
};