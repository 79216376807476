import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export default function useGetBackToCampaignParams() {
  const {
    search
  } = useLocation();
  const fromCampaignId = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('fromCampaignId');
  }, [search]);
  return {
    fromCampaignId
  };
}