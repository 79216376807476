'use es6';

import { getPageSettingsErrors } from 'page-editor-ui/redux/selectors/pageSettingsSelectors';
import { getIsViolatingMultiDomainAccess } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getPublishValidationErrors } from 'page-editor-ui/redux/selectors/pagePublishSelectors';
import isEmpty from 'hs-lodash/isEmpty';
import { validationFinished } from 'ContentEditorUI/redux/actions/appActions';
export const validate = () => (dispatch, getState) => {
  const reduxState = getState();
  const pageSettingsErrors = getPageSettingsErrors(reduxState);
  if (getIsViolatingMultiDomainAccess(reduxState)) {
    pageSettingsErrors.multiDomainError = {
      pane: 'settings',
      message: 'editor.errors.multiDomainViolation'
    };
  }
  const publishFormErrors = getPublishValidationErrors(reduxState);
  const errors = Object.assign({}, pageSettingsErrors, publishFormErrors);
  const valid = isEmpty(errors);
  return dispatch(validationFinished({
    valid,
    errors
  }));
};