import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
// @ts-expect-error not yet migrated
import { getActiveDomainName } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
const MAX_COLOR_PICKER_FAVORITES_COUNT = 6;
export const getSiteSettings = basicSelector(state => state.siteSettings);
const createSettingsSelector = property => createSelector(getSiteSettings, settings => settings ? settings[property] : null);
export const getSiteSettingsBodyOverrideByDomain = createSettingsSelector('bodyOverrideByDomain');
export const getSiteSettingColorFavorites = createSelector([getSiteSettings], settings => {
  const favorites = [];
  if (settings) {
    for (let i = 1; i <= MAX_COLOR_PICKER_FAVORITES_COUNT; i++) {
      const color = settings[`colorPickerFavorite${i}`];
      if (color) {
        favorites.push(color);
      }
    }
  }
});
export const getMergeTagDefaults = createSettingsSelector('mergeTagDefaults');
export const getMembershipSsoEnabledForDomain = createSelector([getSiteSettingsBodyOverrideByDomain, getActiveDomainName], (domainOverrides, domain) => {
  var _domainOverrides$doma, _domainOverrides$doma2;
  return !!(domainOverrides !== null && domainOverrides !== void 0 && (_domainOverrides$doma = domainOverrides[domain]) !== null && _domainOverrides$doma !== void 0 && _domainOverrides$doma.membershipSsoEnabled || domainOverrides !== null && domainOverrides !== void 0 && (_domainOverrides$doma2 = domainOverrides[domain]) !== null && _domainOverrides$doma2 !== void 0 && _domainOverrides$doma2.membershipJwtEnabled);
});
export const getSelfRegistrationEnabled = createSelector([getSiteSettings, getSiteSettingsBodyOverrideByDomain, getActiveDomainName], (siteSettings, domainOverrides, domainName) => {
  var _domainOverrides$doma3;
  // Default set to true through global settings: https://git.hubteam.com/HubSpot/content4j/pull/10826
  const globalSettings = siteSettings.membershipSelfRegistrationEnabled;
  const domainSettings = domainOverrides === null || domainOverrides === void 0 || (_domainOverrides$doma3 = domainOverrides[domainName]) === null || _domainOverrides$doma3 === void 0 ? void 0 : _domainOverrides$doma3.membershipSelfRegistrationEnabled;
  return domainSettings !== null && domainSettings !== void 0 ? domainSettings : globalSettings;
});